import { RemoteUiCrudEditorStore } from "src/stores/common/ContentEditorStore";
import {
    ListResult,
    RemoteUiDefinitionDto,
    Result,
    UPanelAccountCreateArgsDto,
    UPanelAccountDto,
    UPanelEconomicEntityDto,
} from "src/api";
import { action, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { AccountingRouteNames } from "src/pages/accounting/AccountingRoutes";

type AccountingAccountStoreArgs = {
    oid: string;
};

export class AccountingAccountStore extends RemoteUiCrudEditorStore<
    UPanelAccountDto,
    UPanelAccountCreateArgsDto,
    AccountingAccountStoreArgs
> {
    @observable
    economicEntity?: UPanelEconomicEntityDto;

    constructor(root: RootStore) {
        super(root, AccountingRouteNames.accountList, AccountingRouteNames.accountList);
    }

    @action
    protected async onLoaded(): Promise<void> {
        const economicEntity = await this.root.userRpc.accountingEconomicEntity.getCurrent(this.args.oid);
        this.economicEntity = economicEntity.value;
    }

    protected async getAll(): Promise<ListResult<UPanelAccountDto>> {
        return await this.root.userRpc.accountingAccount.getAll(this.args.oid, this.skip, this.take);
    }

    protected async getById(id: string): Promise<UPanelAccountDto | undefined> {
        const response = await this.root.userRpc.accountingAccount.getById(this.args.oid, id);
        return response.success ? response.value : undefined;
    }

    protected createNew(args: UPanelAccountCreateArgsDto): Promise<Result> {
        throw new Error("Account creations are not supported.");
    }

    protected update(): Promise<Result> {
        throw new Error("Account updates are not supported.");
    }

    protected deleteById(id: string): Promise<Result> {
        throw new Error("Account removal is not supported.");
    }

    protected async getRemoteUi(): Promise<RemoteUiDefinitionDto<UPanelAccountCreateArgsDto>> {
        return await this.root.userRpc.accountingAccount.getRemoteUi(this.args.oid);
    }
}

import { Route } from "mobx-state-router";
import { convertRoutes, RouteTransitionHook } from "src/routing/route";
import { StringMap } from "mobx-state-router/dist/types/router-store";
import {
    OrganizationAppPage,
    OrganizationDocumentListPage,
    OrganizationPage,
    OrganizationProjectAddPage,
    OrganizationProjectEditPage,
    OrganizationProjectListPage,
    OrganizationSupportPage,
} from "./OrganizationLazyRoutes";
import React from "react";
import {
    UserAllowOnlyFilledProfilesHook,
    UserAuthorizedOnlyHook,
    UserEnsureProfileLoadedHook,
} from "src/pages/user/UserRoutes";

export const OrganizationEnsureLoaded: RouteTransitionHook = async (root, next, to) => {
    const loadedId = root.organizationStore.args?.oid;
    const loadedOrganization = root.organizationStore.organization;
    const organizationId = to.params["oid"];
    if (loadedId && loadedOrganization && loadedOrganization.id == loadedId && organizationId == loadedId) {
        return;
    } else {
        if (organizationId && organizationId != "user") {
            await root.organizationStore.load({
                oid: organizationId,
            });
        }
    }
};

export enum OrgRouteNames {
    organization = "organization-list",
    organizationSupport = "organization-support",
    organizationDocuments = "organization-documents",
    organizationApp = "organization-app",
    projectList = "organization-project-list",
    projectAdd = "organization-project-add",
    projectEdit = "organization-project-edit",
}

export const OrgViewMap = {
    [OrgRouteNames.organization]: <OrganizationPage />,
    [OrgRouteNames.organizationSupport]: <OrganizationSupportPage />,
    [OrgRouteNames.organizationDocuments]: <OrganizationDocumentListPage />,
    [OrgRouteNames.organizationApp]: <OrganizationAppPage />,
    [OrgRouteNames.projectList]: <OrganizationProjectListPage />,
    [OrgRouteNames.projectAdd]: <OrganizationProjectAddPage />,
    [OrgRouteNames.projectEdit]: <OrganizationProjectEditPage />,
};

export const OrgRoutes: Route[] = convertRoutes([
    {
        pattern: "/organization/:oid",
        name: OrgRouteNames.organization,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            UserAllowOnlyFilledProfilesHook,
            OrganizationEnsureLoaded,
        ],
        onEnter: async (root, to) =>
            root.organizationStore.load({
                oid: to.params["oid"],
            }),
    },
    {
        pattern: "/organization/:oid/documents",
        name: OrgRouteNames.organizationDocuments,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            UserAllowOnlyFilledProfilesHook,
            OrganizationEnsureLoaded,
        ],
        onEnter: async (root, to) =>
            root.organizationDocumentListStore.load({
                oid: to.params["oid"],
            }),
    },
    {
        pattern: "/organization/:oid/support",
        name: OrgRouteNames.organizationSupport,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            UserAllowOnlyFilledProfilesHook,
            OrganizationEnsureLoaded,
        ],
        onEnter: async (root, to) =>
            root.organizationSupportStore.load({
                oid: to.params["oid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/",
        name: OrgRouteNames.projectList,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            UserAllowOnlyFilledProfilesHook,
            OrganizationEnsureLoaded,
        ],
        onEnter: async (root, to) =>
            root.organizationProjectStore.load({
                oid: to.params["oid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/add",
        name: OrgRouteNames.projectAdd,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            UserAllowOnlyFilledProfilesHook,
            OrganizationEnsureLoaded,
        ],
        onEnter: async (root, to) =>
            root.organizationProjectStore.load({
                oid: to.params["oid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:projectId",
        name: OrgRouteNames.projectEdit,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            UserAllowOnlyFilledProfilesHook,
            OrganizationEnsureLoaded,
        ],
        onEnter: async (root, to) =>
            root.organizationProjectStore.loadWithEditor(to.params["projectId"], {
                oid: to.params["oid"],
            }),
    },
    {
        pattern:
            "/organization/:oid/apps/:appId/:arg1?/:arg2?/:arg3?/:arg4?/:arg5?/:arg6?/:arg7?/:arg8?/:arg9?/:arg10?/:arg11?/:arg12?/:arg13?/:arg14?/:arg15?/:arg16?/:arg17?/:arg18?/:arg19?/:arg20?/:arg21?",
        name: OrgRouteNames.organizationApp,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            UserAllowOnlyFilledProfilesHook,
            OrganizationEnsureLoaded,
        ],
        onEnter: async (root, to) => {
            const args = Object.keys(to.params)
                .filter((key) => key.startsWith("arg"))
                .map((key) => to.params[key])
                .filter((value) => Boolean(value));
            const query: StringMap = Object.keys(to.queryParams).reduce(
                (accumulator, key) => ({
                    ...accumulator,
                    [key]: to.queryParams[key].toString(),
                }),
                {}
            );
            await root.organizationAppStore.load({
                oid: to.params["oid"],
                appId: to.params["appId"],
                path: args,
                query: query,
            });
        },
    },
]);

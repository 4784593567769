import { RootStore } from "src/stores/RootStore";
import { RemoteUiCrudEditorStore } from "src/stores/common/ContentEditorStore";
import {
    ConnectableServiceInfoDto,
    ListResult,
    RemoteUiDefinitionDto,
    Result,
    UPanelAdminUserCreateArgsDto,
    UPanelAdminUserDto,
    UPanelConnectableServiceDto,
} from "src/api";
import { AdminRouteNames } from "src/pages/admin/AdminRoutes";
import { action, observable } from "mobx";
import { ConfigurableAutoCompleteStore } from "src/components/AutoCompleteSelect/AutoCompleteSelectStore";

type AdminUserStoreArgs = {
    oid: string;
    pid: string;
};

export class AdminUserStore extends RemoteUiCrudEditorStore<
    UPanelAdminUserDto,
    UPanelAdminUserCreateArgsDto,
    AdminUserStoreArgs
> {
    @observable connectedServices: ConnectableServiceInfoDto[] = [];
    @observable connectable: ConfigurableAutoCompleteStore<UPanelConnectableServiceDto>;

    constructor(root: RootStore) {
        super(root, AdminRouteNames.userList, AdminRouteNames.userList);
        this.connectable = new ConfigurableAutoCompleteStore<UPanelConnectableServiceDto>({
            suggest: (query) =>
                this.root.userRpc.createAdminInstanceRpc(this.args).adminConnectableService.getAll(query, 0, 20),
        });
    }

    @action
    async disconnect(id: string): Promise<void> {
        await this.root.userRpc
            .createAdminInstanceRpc(this.args)
            .adminUser.disconnectService(parseInt(this.selectedItemId), parseInt(id));
        await this.onLoaded();
    }

    @action
    async connect(): Promise<void> {
        const id = this.connectable.value?.id;
        if (id === undefined) return;
        await this.root.userRpc
            .createAdminInstanceRpc(this.args)
            .adminUser.connectService(parseInt(this.selectedItemId), parseInt(id));
        await this.onLoaded();
    }

    protected async onLoaded(): Promise<void> {
        if (this.selectedItemId === this.dummySelectedItemId) return;
        this.connectedServices = await this.root.userRpc
            .createAdminInstanceRpc(this.args)
            .adminUser.getConnectedServices(parseInt(this.selectedItemId));
    }

    protected getAll(): Promise<ListResult<UPanelAdminUserDto>> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminUser.getAll(this.skip, this.take);
    }

    protected getById(id: number): Promise<UPanelAdminUserDto> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminUser.getById(id);
    }

    protected createNew(args: UPanelAdminUserCreateArgsDto): Promise<Result> {
        const id = parseInt(this.selectedItemId);
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminUser.update(id, args);
    }

    protected update(args: UPanelAdminUserCreateArgsDto): Promise<Result> {
        const id = parseInt(this.selectedItemId);
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminUser.update(id, args);
    }

    protected deleteById(id: number): Promise<Result> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminUser.delete(id);
    }

    protected getRemoteUi(): Promise<RemoteUiDefinitionDto<UPanelAdminUserCreateArgsDto>> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminUser.getRemoteUi();
    }
}

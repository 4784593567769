import { RootStore } from "src/stores/RootStore";
import { RemoteUiCrudEditorStore } from "src/stores/common/ContentEditorStore";
import {
    ConnectableServiceInfoDto,
    ListResult,
    RemoteUiDefinitionDto,
    Result,
    UPanelAdminOrganizationCreateArgsDto,
    UPanelAdminOrganizationDto,
    UPanelConnectableServiceDto,
} from "src/api";
import { AdminRouteNames } from "src/pages/admin/AdminRoutes";
import { action, observable } from "mobx";
import { ConfigurableAutoCompleteStore } from "src/components/AutoCompleteSelect/AutoCompleteSelectStore";

type AdminOrganizationStoreArgs = {
    oid: string;
    pid: string;
};

export class AdminOrganizationStore extends RemoteUiCrudEditorStore<
    UPanelAdminOrganizationDto,
    UPanelAdminOrganizationCreateArgsDto,
    AdminOrganizationStoreArgs
> {
    @observable connectedServices: ConnectableServiceInfoDto[] = [];
    @observable connectable: ConfigurableAutoCompleteStore<UPanelConnectableServiceDto>;

    constructor(root: RootStore) {
        super(root, AdminRouteNames.organizationList, AdminRouteNames.organizationList);
        this.connectable = new ConfigurableAutoCompleteStore<UPanelConnectableServiceDto>({
            suggest: (query) =>
                this.root.userRpc.createAdminInstanceRpc(this.args).adminConnectableService.getAll(query, 0, 20),
        });
    }

    @action
    async disconnect(id: string): Promise<void> {
        await this.root.userRpc
            .createAdminInstanceRpc(this.args)
            .adminOrganization.disconnectService(this.selectedItemId, parseInt(id));
        await this.onLoaded();
    }

    @action
    async connect(): Promise<void> {
        const id = this.connectable.value?.id;
        if (id === undefined) return;
        await this.root.userRpc
            .createAdminInstanceRpc(this.args)
            .adminOrganization.connectService(this.selectedItemId, parseInt(id));
        await this.onLoaded();
    }

    protected async onLoaded(): Promise<void> {
        if (this.selectedItemId === this.dummySelectedItemId) return;
        this.connectedServices = await this.root.userRpc
            .createAdminInstanceRpc(this.args)
            .adminOrganization.getConnectedServices(this.selectedItemId);
    }

    protected getAll(): Promise<ListResult<UPanelAdminOrganizationDto>> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminOrganization.getAll(this.skip, this.take);
    }

    protected getById(id: string): Promise<UPanelAdminOrganizationDto | undefined> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminOrganization.getById(id);
    }

    protected update(args: UPanelAdminOrganizationCreateArgsDto): Promise<Result> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminOrganization.update(this.selectedItemId, args);
    }

    protected deleteById(id: string): Promise<Result> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminOrganization.delete(id);
    }

    protected createNew(args: UPanelAdminOrganizationCreateArgsDto): Promise<Result> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminOrganization.create(args);
    }

    protected async getRemoteUi(): Promise<RemoteUiDefinitionDto<UPanelAdminOrganizationCreateArgsDto>> {
        return await this.root.userRpc.createAdminInstanceRpc(this.args).adminOrganization.getRemoteUi();
    }
}

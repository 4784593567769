import { action, observable } from "mobx";
import { RouterState, RouterStore } from "mobx-state-router";
import { UserLoginStore } from "src/stores/user/UserLoginStore";
import { CoreApi } from "src/api";
import { PreviewBoxStore } from "src/components/PreviewBox/PreviewBoxStore";
import { UserUpdateProfileStore } from "src/stores/user/UserUpdateProfileStore";
import { UserOrganizationListStore } from "src/stores/user/UserOrganizationListStore";
import { UserDocumentListStore } from "src/stores/user/UserDocumentListStore";
import { UserSupportStore } from "src/stores/user/UserSupportStore";
import { OrganizationStore } from "src/stores/org/OrganizationStore";
import { OrganizationDocumentListStore } from "src/stores/org/OrganizationDocumentListStore";
import { OrganizationSupportStore } from "src/stores/org/OrganizationSupportStore";
import { OrganizationLoginStore } from "src/stores/org/OrganizationLoginStore";
import { UserProfileStore } from "src/stores/user/UserProfileStore";
import { UserLoginRedirectStore } from "src/stores/user/UserLoginRedirectStore";
import createBrowserHistory from "history/createBrowserHistory";
import { OrganizationAppStore } from "src/stores/org/OrganizationAppStore";
import { CustomHistoryAdapter } from "src/routing/CustomHistoryAdapter";
import { UserAppStore } from "src/stores/user/UserAppStore";
import { OrganizationProjectStore } from "src/stores/org/OrganizationProjectStore";
import { AdminOrganizationStore } from "src/stores/admin/AdminOrganizationStore";
import { AdminUserStore } from "src/stores/admin/AdminUserStore";
import { ProjectStore } from "src/stores/project/ProjectStore";
import { ProjectAccountStore } from "src/stores/project/ProjectAccountStore";
import { ProjectSubAccountStore } from "src/stores/project/ProjectSubAccountStore";
import { ProjectTransactionStore } from "src/stores/project/ProjectTransactionStore";
import { ProjectEconomicEntityStore } from "src/stores/project/ProjectEconomicEntityStore";
import { ProjectCurrencyStore } from "src/stores/project/ProjectCurrencyStore";
import { ProjectServiceStore } from "src/stores/project/ProjectServiceStore";
import { ProjectTariffStore } from "src/stores/project/ProjectTariffStore";
import { ProjectTariffVersionStore } from "src/stores/project/ProjectTariffVersionStore";
import { ProjectContractStore } from "src/stores/project/ProjectContractStore";
import { ProjectServiceProvisionStore } from "src/stores/project/ProjectServiceProvisionStore";
import { Routes } from "src/routes";
import { AnonRouteNames } from "src/pages/user/UserRoutes";
import { AccountingAccountStore } from "src/stores/accounting/AccountingAccountStore";
import { AccountingSubAccountStore } from "src/stores/accounting/AccountingSubAccountStore";
import { AccountingTransactionStore } from "src/stores/accounting/AccountingTransactionStore";
import { AccountingServiceProvisionStore } from "src/stores/accounting/AccountingServiceProvisionStore";
import { AccountingServiceStore } from "src/stores/accounting/AccountingServiceStore";
import { AccountingContractStore } from "src/stores/accounting/AccountingContractStore";
import { AdminBookKeeperConnectionStore } from "src/stores/admin/AdminBookKeeperConnectionStore";
import { AdminOrganizationBookKeeperConnectionStore } from "src/stores/admin/AdminOrganizationBookKeeperConnectionStore";
import { AdminUPanelApiConnectionStore } from "src/stores/admin/AdminUPanelApiConnectionStore";
import { AdminConnectableServiceStore } from "src/stores/admin/AdminConnectableServiceStore";
import { AdminProjectStore } from "src/stores/admin/AdminProjectStore";
import { AdminUserSupportStore } from "src/stores/admin/AdminUserSupportStore";
import { AdminOrganizationSupportStore } from "src/stores/admin/AdminOrganizationSupportStore";

const apiUrl = "/tsrpc";

export class SecureCoreApi extends CoreApi {
    @observable private token: string | null;

    private readonly localStorageTokenKey = `up-auth-token:X-User-Auth`;

    constructor(path: string, private readonly headerKey: string, private readonly root: RootStore) {
        super(path, async (url: string, request: RequestInit) => {
            request.credentials = "same-origin";
            request.headers = { "Accept-Language": root.lang };
            if (this.token) request.headers[headerKey] = this.token;
            const res = await fetch(url, request);
            if (res.status == 401) {
                window.location.href = "/";
                window.location.reload();
                this.resetStore();
                await new Promise(() => {
                    // Never
                });
            }
            return res;
        });

        this.token = window.localStorage.getItem(this.localStorageTokenKey) || null;
    }

    isAuthorized() {
        return this.token != null;
    }

    getUserToken(): string {
        if (this.token) return this.token;
        throw new Error('Token is not set.')
    }

    @action setUserToken(token: string) {
        this.token = token;
        window.localStorage.setItem(this.localStorageTokenKey, token);
    }

    @action resetUserToken() {
        this.token = null;
        window.localStorage.removeItem(this.localStorageTokenKey);
    }

    @action resetStore = () => {
        this.resetUserToken();
    };

    createAdminInstanceRpc(args: { oid: string; pid: string }): SecureCoreApi {
        const path = `/api/org/${args.oid}/project/${args.pid}/upanel/tsrpc`;
        const secure = new SecureCoreApi(path, this.headerKey, this.root);
        if (this.token) secure.setUserToken(this.token);
        return secure;
    }
}

export class RootStore {
    @observable lang: string = window.localStorage.getItem("lang") ?? navigator.language.substr(0, 2) ?? "ru";
    @observable userRpc = new SecureCoreApi(apiUrl, "X-User-Auth", this);
    @observable previewStore = new PreviewBoxStore();
    @observable routerStore = new RouterStore(this, Routes, new RouterState(AnonRouteNames.notFound));
    @observable historyAdapter = new CustomHistoryAdapter(this.routerStore, createBrowserHistory(), { name: "UPanel" });

    // User specific routes
    @observable userAppStore = new UserAppStore(this);
    @observable userLoginStore = new UserLoginStore(this);
    @observable userLoginRedirectStore = new UserLoginRedirectStore(this);
    @observable userProfileStore = new UserProfileStore(this);
    @observable userUpdateProfileStore = new UserUpdateProfileStore(this);
    @observable userOrganizationListStore = new UserOrganizationListStore(this);
    @observable userDocumentListStore = new UserDocumentListStore(this);
    @observable userSupportStore = new UserSupportStore(this);

    // Accounting specific routes
    @observable accountingAccountStore = new AccountingAccountStore(this);
    @observable accountingSubAccountStore = new AccountingSubAccountStore(this);
    @observable accountingTransactionStore = new AccountingTransactionStore(this);
    @observable accountingServiceProvisionStore = new AccountingServiceProvisionStore(this);
    @observable accountingServiceStore = new AccountingServiceStore(this);
    @observable accountingContractStore = new AccountingContractStore(this);

    // Organization specific routes
    @observable organizationStore = new OrganizationStore(this);
    @observable organizationAppStore = new OrganizationAppStore(this);
    @observable organizationLoginStore = new OrganizationLoginStore(this);
    @observable organizationDocumentListStore = new OrganizationDocumentListStore(this);
    @observable organizationSupportStore = new OrganizationSupportStore(this);
    @observable organizationProjectStore = new OrganizationProjectStore(this);

    // Project specific routes
    @observable projectStore = new ProjectStore(this);
    @observable projectAccountStore = new ProjectAccountStore(this);
    @observable projectSubAccountStore = new ProjectSubAccountStore(this);
    @observable projectTransactionStore = new ProjectTransactionStore(this);
    @observable projectEconomicEntityStore = new ProjectEconomicEntityStore(this);
    @observable projectCurrencyStore = new ProjectCurrencyStore(this);
    @observable projectServiceStore = new ProjectServiceStore(this);
    @observable projectTariffStore = new ProjectTariffStore(this);
    @observable projectTariffVersionStore = new ProjectTariffVersionStore(this);
    @observable projectContractStore = new ProjectContractStore(this);
    @observable projectServiceProvisionStore = new ProjectServiceProvisionStore(this);

    // Admin specific routes
    @observable adminOrganizationStore = new AdminOrganizationStore(this);
    @observable adminUserStore = new AdminUserStore(this);
    @observable adminBookKeeperConnectionStore = new AdminBookKeeperConnectionStore(this);
    @observable adminOrganizationBookKeeperConnectionStore = new AdminOrganizationBookKeeperConnectionStore(this);
    @observable adminUPanelApiConnectionStore = new AdminUPanelApiConnectionStore(this);
    @observable adminConnectableServiceStore = new AdminConnectableServiceStore(this);
    @observable adminProjectStore = new AdminProjectStore(this);
    @observable adminUserSupportStore = new AdminUserSupportStore(this);
    @observable adminOrganizationSupportStore = new AdminOrganizationSupportStore(this);
}

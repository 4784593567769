import { lazy } from "react";

export const ProjectConnectPage = lazy(() => import("src/pages/project/ProjectConnect/ProjectConnectPage"));
export const ProjectEconomicEntityListPage = lazy(
    () => import("src/pages/project/ProjectEconomicEntity/ProjectEconomicEntityListPage")
);
export const ProjectEconomicEntityAddPage = lazy(
    () => import("src/pages/project/ProjectEconomicEntity/ProjectEconomicEntityAddPage")
);
export const ProjectEconomicEntityEditPage = lazy(
    () => import("src/pages/project/ProjectEconomicEntity/ProjectEconomicEntityEditPage")
);
export const ProjectAccountListPage = lazy(() => import("src/pages/project/ProjectAccount/ProjectAccountListPage"));
export const ProjectAccountEditPage = lazy(() => import("src/pages/project/ProjectAccount/ProjectAccountEditPage"));
export const ProjectAccountAddPage = lazy(() => import("src/pages/project/ProjectAccount/ProjectAccountAddPage"));
export const ProjectCurrencyListPage = lazy(() => import("src/pages/project/ProjectCurrency/ProjectCurrencyListPage"));
export const ProjectCurrencyAddPage = lazy(() => import("src/pages/project/ProjectCurrency/ProjectCurrencyAddPage"));
export const ProjectCurrencyEditPage = lazy(() => import("src/pages/project/ProjectCurrency/ProjectCurrencyEditPage"));
export const ProjectServiceListPage = lazy(() => import("src/pages/project/ProjectService/ProjectServiceListPage"));
export const ProjectServiceAddPage = lazy(() => import("src/pages/project/ProjectService/ProjectServiceAddPage"));
export const ProjectServiceEditPage = lazy(() => import("src/pages/project/ProjectService/ProjectServiceEditPage"));
export const ProjectTariffListPage = lazy(() => import("src/pages/project/ProjectTariff/ProjectTariffListPage"));
export const ProjectTariffAddPage = lazy(() => import("src/pages/project/ProjectTariff/ProjectTariffAddPage"));
export const ProjectTariffEditPage = lazy(() => import("src/pages/project/ProjectTariff/ProjectTariffEditPage"));
export const ProjectTariffVersionAddPage = lazy(
    () => import("src/pages/project/ProjectTariffVersion/ProjectTariffVersionAddPage")
);
export const ProjectTariffVersionEditPage = lazy(
    () => import("src/pages/project/ProjectTariffVersion/ProjectTariffVersionEditPage")
);
export const ProjectContractListPage = lazy(() => import("src/pages/project/ProjectContract/ProjectContractListPage"));
export const ProjectContractAddPage = lazy(() => import("src/pages/project/ProjectContract/ProjectContractAddPage"));
export const ProjectContractEditPage = lazy(() => import("src/pages/project/ProjectContract/ProjectContractEditPage"));
export const ProjectSubAccountListPage = lazy(
    () => import("src/pages/project/ProjectSubAccount/ProjectSubAccountListPage")
);
export const ProjectSubAccountEditPage = lazy(
    () => import("src/pages/project/ProjectSubAccount/ProjectSubAccountEditPage")
);
export const ProjectSubAccountAddPage = lazy(
    () => import("src/pages/project/ProjectSubAccount/ProjectSubAccountAddPage")
);
export const ProjectTransactionListPage = lazy(
    () => import("src/pages/project/ProjectTransaction/ProjectTransactionListPage")
);
export const ProjectTransactionPage = lazy(() => import("src/pages/project/ProjectTransaction/ProjectTransactionPage"));
export const ProjectServiceProvisionListPage = lazy(
    () => import("src/pages/project/ProjectServiceProvision/ProjectServiceProvisionListPage")
);
export const ProjectServiceProvisionAddPage = lazy(
    () => import("src/pages/project/ProjectServiceProvision/ProjectServiceProvisionAddPage")
);

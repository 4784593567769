import { RemoteUiCrudEditorStore } from "src/stores/common/ContentEditorStore";
import {
    ListResult,
    RemoteUiDefinitionDto,
    Result,
    UPanelEconomicEntityCreateArgsDto,
    UPanelEconomicEntityDto,
} from "src/api";
import { RootStore } from "src/stores/RootStore";
import { ProjectRouteNames } from "src/pages/project/ProjectRoutes";
import { observable } from "mobx";

type ProjectEconomicEntityRouteArgs = {
    oid: string;
    pid: string;
};

export class ProjectEconomicEntityStore extends RemoteUiCrudEditorStore<
    UPanelEconomicEntityDto,
    UPanelEconomicEntityCreateArgsDto,
    ProjectEconomicEntityRouteArgs
> {
    @observable economicEntity?: UPanelEconomicEntityDto;
    @observable rootEconomicEntity?: UPanelEconomicEntityDto;

    constructor(root: RootStore) {
        super(root, ProjectRouteNames.economicEntityList, ProjectRouteNames.economicEntityAdd);
    }

    protected async onLoaded(): Promise<void> {
        const eeId = this.selectedItemId;
        if (eeId === this.dummySelectedItemId) return;
        const response = await this.root.userRpc.projectEconomicEntity.getById(this.args.oid, this.args.pid, eeId);
        const root = await this.root.userRpc.projectEconomicEntity.getRootEconomicEntity(this.args.oid, this.args.pid);
        this.economicEntity = response.value;
        this.rootEconomicEntity = root.value;
    }

    protected getRemoteUi(): Promise<RemoteUiDefinitionDto<UPanelEconomicEntityCreateArgsDto>> {
        return this.root.userRpc.projectEconomicEntity.getRemoteUi(this.args.oid, this.args.pid);
    }

    protected createNew(args: UPanelEconomicEntityCreateArgsDto): Promise<Result> {
        return this.root.userRpc.projectEconomicEntity.create(this.args.oid, this.args.pid, args);
    }

    protected getAll(): Promise<ListResult<UPanelEconomicEntityDto>> {
        return this.root.userRpc.projectEconomicEntity.getAll(this.args.oid, this.args.pid, this.skip, this.take);
    }

    protected async getById(id: string): Promise<UPanelEconomicEntityDto | undefined> {
        const response = await this.root.userRpc.projectEconomicEntity.getById(this.args.oid, this.args.pid, id);
        return response.success ? response.value : undefined;
    }

    protected update(args: UPanelEconomicEntityCreateArgsDto): Promise<Result> {
        return this.root.userRpc.projectEconomicEntity.update(this.args.oid, this.args.pid, this.selectedItemId!, args);
    }

    protected deleteById(id: string): Promise<Result> {
        return this.root.userRpc.projectEconomicEntity.delete(this.args.oid, this.args.pid, id);
    }
}

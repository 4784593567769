import {
    AnonNotFoundPage,
    UserAppPage,
    UserDocumentListPage,
    UserLoginPage,
    UserLoginRedirectPage,
    UserOrganizationListPage,
    UserSupportPage,
} from "./UserLazyRoutes";
import React from "react";
import { Route, RouterState } from "mobx-state-router";
import { convertRoutes, RouteTransitionHook } from "src/routing/route";
import { StringMap } from "mobx-state-router/dist/types/router-store";

export const AnonRouteToRootIfAuthorizedHook: RouteTransitionHook = (root) => {
    if (root.userRpc.isAuthorized()) throw new RouterState(UserRouteNames.organizations);
};

export const UserAuthorizedOnlyHook: RouteTransitionHook = (root) => {
    if (!root.userRpc.isAuthorized()) throw new RouterState(AnonRouteNames.userLogin);
};

export const UserEnsureProfileLoadedHook: RouteTransitionHook = async (root) => {
    if (!root.userProfileStore.profile) await root.userProfileStore.loadProfile();
};

export const UserAllowOnlyFilledProfilesHook: RouteTransitionHook = async () => {
    const profileFilled = true;
    if (!profileFilled) throw new RouterState(UserRouteNames.profile);
};

export enum AnonRouteNames {
    notFound = "anon-not-found",
    userLogin = "anon-user-login",
    userLoginRedirect = "anon-user-login-redirect",
}

export enum UserRouteNames {
    profile = "user-profile",
    organizations = "user-organizations",
    documents = "user-documents",
    support = "user-support",
    userApp = "user-app",
}

export const AnonViewMap = {
    [AnonRouteNames.notFound]: <AnonNotFoundPage />,
    [AnonRouteNames.userLogin]: <UserLoginPage />,
    [AnonRouteNames.userLoginRedirect]: <UserLoginRedirectPage />,
};

export const UserViewMap = {
    [UserRouteNames.organizations]: <UserOrganizationListPage />,
    [UserRouteNames.documents]: <UserDocumentListPage />,
    [UserRouteNames.support]: <UserSupportPage />,
    [UserRouteNames.userApp]: <UserAppPage />,
};

export const AnonRoutes: Route[] = convertRoutes([
    {
        pattern: "/not-found",
        name: AnonRouteNames.notFound,
    },
    {
        pattern: "/login",
        name: AnonRouteNames.userLogin,
        hooks: [AnonRouteToRootIfAuthorizedHook],
        onEnter: async (root) => root.userLoginStore.login(),
    },
    {
        pattern: "/login/redirect",
        name: AnonRouteNames.userLoginRedirect,
        hooks: [AnonRouteToRootIfAuthorizedHook],
        onEnter: async (root, to) => root.userLoginRedirectStore.load(to),
    },
]);

const UserPersonalRoutes: Route[] = convertRoutes([
    {
        pattern: "/personal/profile",
        name: UserRouteNames.profile,
        hooks: [UserAuthorizedOnlyHook, UserEnsureProfileLoadedHook, UserAllowOnlyFilledProfilesHook],
    },
    {
        pattern: "/",
        name: UserRouteNames.organizations,
        hooks: [UserAuthorizedOnlyHook, UserEnsureProfileLoadedHook, UserAllowOnlyFilledProfilesHook],
        onEnter: async (root) => root.userOrganizationListStore.load(),
    },
    {
        pattern: "/documents",
        name: UserRouteNames.documents,
        hooks: [UserAuthorizedOnlyHook, UserEnsureProfileLoadedHook, UserAllowOnlyFilledProfilesHook],
        onEnter: async (root) => root.userDocumentListStore.load(),
    },
    {
        pattern: "/support",
        name: UserRouteNames.support,
        hooks: [UserAuthorizedOnlyHook, UserEnsureProfileLoadedHook, UserAllowOnlyFilledProfilesHook],
        onEnter: async (root) => root.userSupportStore.load(),
    },
    {
        pattern:
            "/apps/:appId/:arg1?/:arg2?/:arg3?/:arg4?/:arg5?/:arg6?/:arg7?/:arg8?/:arg9?/:arg10?/:arg11?/:arg12?/:arg13?/:arg14?/:arg15?/:arg16?/:arg17?/:arg18?/:arg19?/:arg20?/:arg21?",
        name: UserRouteNames.userApp,
        hooks: [UserAuthorizedOnlyHook, UserEnsureProfileLoadedHook, UserAllowOnlyFilledProfilesHook],
        onEnter: async (root, to) => {
            const args = Object.keys(to.params)
                .filter((key) => key.startsWith("arg"))
                .map((key) => to.params[key])
                .filter((value) => Boolean(value));
            const query: StringMap = Object.keys(to.queryParams).reduce(
                (accumulator, key) => ({
                    ...accumulator,
                    [key]: to.queryParams[key].toString(),
                }),
                {}
            );
            await root.userAppStore.load({
                appId: to.params["appId"],
                path: args,
                query: query,
            });
        },
    },
]);

export const UserRoutes: Route[] = [...UserPersonalRoutes];

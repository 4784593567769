import * as React from "react";
import { FC } from "react";
import { RouterLink, RouterView } from "mobx-state-router";
import { useRootStore } from "src/utilities";
import { AdminRouteNames, AdminViewMap } from "src/pages/admin/AdminRoutes";
import { useObserver } from "mobx-react";
import { SuspensePlaceholder } from "src/components/SuspensePlaceholder/SuspensePlaceholder";
import { LocalizedNavigationDelimiter, NavigationSidebar } from "src/components/NavigationSidebar/NavigationSidebar";
import navigationStyle from "src/components/NavigationSidebar/navigation-sidebar.module.css";
import style from "../../styles/page.module.css";
import iconGroup from "src/assets/icon/groups.svg";
import iconPerson from "src/assets/icon/person.svg";
import iconLogOut from "src/assets/icon/meeting_room.svg";
import { LocalizedText, useLocalizedText } from "src/locales/LocalizedText";
import { useIntl } from "react-intl";
import iconShopTwo from "src/assets/icon/shop_two.svg";
import iconShop from "src/assets/icon/shop.svg";
import { Logo } from "src/components/UI/Logo/Logo";
import { AccountSelect, AccountSelectStore } from "src/components/AccountSelector/AccountSelector";
import { ProjectStore } from "src/stores/project/ProjectStore";
import { OrganizationStore } from "src/stores/org/OrganizationStore";
import { ProjectRouteNames } from "src/pages/project/ProjectRoutes";

const AdminNavigation: FC<{
    title: string;
    description: string;
    accountSelectStore: AccountSelectStore;
    store: ProjectStore;
    organizationStore: OrganizationStore;
}> = ({ title, description, accountSelectStore, store, organizationStore }) => {
    const intl = useIntl();
    return useObserver(() => (
        <NavigationSidebar>
            <AccountSelect store={accountSelectStore}>
                <Logo color={"dark"} className={"mt-4"} title={title} description={description} />
            </AccountSelect>
            <LocalizedNavigationDelimiter
                content={useLocalizedText({ id: "comAdminNavigation__LocalizedNavigationDelimiter__control" }, intl)}
            />
            <RouterLink
                activeClassName={navigationStyle.navigationActive}
                routeName={AdminRouteNames.userList}
                params={{ pid: store.pid, oid: organizationStore.oid }}
            >
                <img src={iconPerson} alt="" className="mr-3" />
                <LocalizedText id={"comAdminNavigation__RouterLink__userList"} />
            </RouterLink>
            <RouterLink
                activeClassName={navigationStyle.navigationActive}
                routeName={AdminRouteNames.organizationList}
                params={{ pid: store.pid, oid: organizationStore.oid }}
            >
                <img src={iconGroup} alt="" className="mr-3" />
                <LocalizedText id={"comAdminNavigation__RouterLink__organizationList"} />
            </RouterLink>
            <LocalizedNavigationDelimiter
                content={useLocalizedText({ id: "comAdminNavigation__LocalizedNavigationDelimiter__billings" }, intl)}
            />
            <RouterLink
                activeClassName={navigationStyle.navigationActive}
                routeName={AdminRouteNames.bookKeeperConnectionList}
                params={{ pid: store.pid, oid: organizationStore.oid }}
            >
                <img src={iconShop} alt="" className="mr-3" />
                <LocalizedText id={"comAdminNavigation__RouterLink__bookKeeperConnectionList"} />
            </RouterLink>
            <RouterLink
                activeClassName={navigationStyle.navigationActive}
                routeName={AdminRouteNames.organizationBookKeeperConnectionList}
                params={{ pid: store.pid, oid: organizationStore.oid }}
            >
                <img src={iconShopTwo} alt="" className="mr-3" />
                <LocalizedText id={"comAdminNavigation__RouterLink__organizationBookKeeperConnectionList"} />
            </RouterLink>
            <LocalizedNavigationDelimiter
                content={useLocalizedText({ id: "comAdminNavigation__LocalizedNavigationDelimiter__upanel" }, intl)}
            />
            <RouterLink
                activeClassName={navigationStyle.navigationActive}
                routeName={AdminRouteNames.uPanelApiConnectionList}
                params={{ pid: store.pid, oid: organizationStore.oid }}
            >
                <img src={iconShop} alt="" className="mr-3" />
                <LocalizedText id={"comAdminNavigation__RouterLink__uPanelApiConnectionList"} />
            </RouterLink>
            <LocalizedNavigationDelimiter
                content={useLocalizedText({ id: "comAdminNavigation__LocalizedNavigationDelimiter__services" }, intl)}
            />
            <RouterLink
                activeClassName={navigationStyle.navigationActive}
                routeName={AdminRouteNames.connectableServiceList}
                params={{ pid: store.pid, oid: organizationStore.oid }}
            >
                <img src={iconShop} alt="" className="mr-3" />
                <LocalizedText id={"comAdminNavigation__RouterLink__connectableServiceList"} />
            </RouterLink>
            <LocalizedNavigationDelimiter
                content={useLocalizedText({ id: "comAdminNavigation__LocalizedNavigationDelimiter__admin" }, intl)}
            />
            <RouterLink
                activeClassName={navigationStyle.navigationActive}
                routeName={ProjectRouteNames.economicEntityList}
                params={{ pid: store.pid, oid: organizationStore.oid }}
            >
                <img src={iconLogOut} alt="" className="mr-3" />
                <LocalizedText id={"comAdminNavigation__RouterLink__logout"} />
            </RouterLink>
        </NavigationSidebar>
    ));
};

export const AdminShell: FC = () => {
    const {
        rootStore: { routerStore, organizationStore, projectStore },
    } = useRootStore();
    const intl = useIntl();
    return useObserver(() => (
        <div className={style.fullHeight}>
            <div className={style.fullHeight + " container ml-0 " + style.fullWidth}>
                <div className={style.flexList + " row " + style.fullHeight}>
                    <div className={"col-lg-2 " + style.adminBackground}>
                        <div className={"sticky-top mt-4"}>
                            <AdminNavigation
                                store={projectStore}
                                organizationStore={organizationStore}
                                accountSelectStore={projectStore}
                                title={
                                    projectStore.profile?.projectName ??
                                    useLocalizedText({ id: "comAdminShell__title" }, intl)
                                }
                                description={useLocalizedText({ id: "comAdminShell__description" }, intl)}
                            />
                        </div>
                    </div>
                    <div className={"col-lg-10 p-0 m-0"}>
                        <SuspensePlaceholder>
                            <RouterView routerStore={routerStore} viewMap={AdminViewMap} />
                        </SuspensePlaceholder>
                    </div>
                </div>
            </div>
        </div>
    ));
};

import { RootStore } from "src/stores/RootStore";
import { observable } from "mobx";
import { convertFileToBytes } from "src/utilities";
import { DocumentDto } from "src/api";
import { DocumentSearchStore, DocumentUploadStore } from "src/components/DocumentList/DocumentListStore";
import { ReactTableStore } from "src/stores/common/ReactTableStore";

export class OrganizationDocumentUploadStore extends DocumentUploadStore {
    constructor(private readonly root: RootStore) {
        super();
    }

    async performUpload(): Promise<void> {
        const file = this.file!;
        const extension = file.name.split(".").pop()!;
        const bytes = await convertFileToBytes(file);
        await this.root.userRpc.organizationDocument.create(
            this.root.organizationDocumentListStore.args.oid,
            this.title,
            extension,
            bytes
        );
        await this.root.organizationDocumentListStore.refresh();
    }
}

export class OrganizationDocumentSearchStore extends DocumentSearchStore {
    constructor(private readonly root: RootStore) {
        super();
    }

    async performSearch(): Promise<void> {
        this.root.organizationDocumentListStore.page = 0;
        await this.root.organizationDocumentListStore.refresh();
    }
}

type OrganizationDocumentListRouteArgs = {
    oid: string;
};

export class OrganizationDocumentListStore extends ReactTableStore<DocumentDto, OrganizationDocumentListRouteArgs> {
    @observable searchStore: OrganizationDocumentSearchStore;
    @observable uploadStore: OrganizationDocumentUploadStore;

    constructor(private readonly root: RootStore) {
        super();
        this.searchStore = new OrganizationDocumentSearchStore(root);
        this.uploadStore = new OrganizationDocumentUploadStore(root);
    }

    async refresh(): Promise<void> {
        const documents = await this.root.userRpc.organizationDocument.getAll(
            this.args.oid,
            this.skip,
            this.take,
            this.searchStore.query
        );
        this.fillItems(documents.items, documents.totalCount);
    }
}

import { RootStore } from "src/stores/RootStore";
import { RemoteUiCrudEditorStore } from "src/stores/common/ContentEditorStore";
import {
    ListResult,
    UPanelProjectCreateArgsDto,
    UPanelProjectDto,
    UPanelProjectUpdateArgsDto,
    RemoteUiDefinitionDto,
    Result,
} from "src/api";
import { OrgRouteNames } from "src/pages/org/OrganizationRoutes";

type OrganizationProjectStoreArgs = {
    oid: string;
};

export class OrganizationProjectStore extends RemoteUiCrudEditorStore<
    UPanelProjectDto,
    UPanelProjectCreateArgsDto | UPanelProjectUpdateArgsDto,
    OrganizationProjectStoreArgs
> {
    constructor(root: RootStore) {
        super(root, OrgRouteNames.projectList, OrgRouteNames.projectAdd);
    }

    protected createNew(args: UPanelProjectCreateArgsDto): Promise<Result> {
        return this.root.userRpc.organizationProject.create(this.args.oid, args);
    }

    protected getAll(): Promise<ListResult<UPanelProjectDto>> {
        return this.root.userRpc.organizationProject.getAll(this.args.oid, this.skip, this.take);
    }

    protected async getById(id: string): Promise<UPanelProjectDto | undefined> {
        const response = await this.root.userRpc.organizationProject.getById(this.args.oid, id);
        return response.success ? response.value : undefined;
    }

    protected update(args: UPanelProjectUpdateArgsDto): Promise<Result> {
        return this.root.userRpc.organizationProject.update(this.args.oid, this.selectedItemId, args);
    }

    protected deleteById(id: string): Promise<Result> {
        return this.root.userRpc.organizationProject.delete(this.args.oid, id);
    }

    protected getRemoteUi(): Promise<RemoteUiDefinitionDto<UPanelProjectCreateArgsDto | UPanelProjectUpdateArgsDto>> {
        return this.selectedItemId == this.dummySelectedItemId
            ? this.root.userRpc.organizationProject.getCreateRemoteUi(this.args.oid)
            : this.root.userRpc.organizationProject.getUpdateRemoteUi(this.args.oid);
    }
}

import React, { FC } from "react";
import styles from "./logo.module.css";

export type LogoProps = {
    title: string;
    description: string;
    color?: "light" | "dark";
    className?: string;
};

export const Logo: FC<LogoProps> = ({ color = "light", className = "", title, description }) => (
    <div className={styles[color] + " d-flex " + styles[className]}>
        <div className={styles.Logo__avatar}>UP</div>
        <div className={styles.Logo__text + " d-flex flex-column"}>
            <div className={styles.Logo__title}>{title}</div>
            <div className={styles.Logo__subtitle}>{description}</div>
        </div>
    </div>
);

import { ListResult } from "src/api";
import { action, observable, reaction } from "mobx";

export interface AutoCompleteSelectStore<TItem> {
    items: TItem[];
    value: TItem | undefined;
    labelField: string;
    valueField: string;

    suggest(query: string): Promise<void>;
}

type ConfigurableAutoCompleteStoreParams<TItem> = {
    labelField?: string;
    valueField?: string;
    suggest?: (query: string) => Promise<ListResult<TItem>> | Promise<TItem[]> | Promise<undefined>;
    onSelectionChange?: () => Promise<void>;
};

export class ConfigurableAutoCompleteStore<TItem> implements AutoCompleteSelectStore<TItem> {
    @observable items: TItem[] = [];
    @observable labelField: string;
    @observable valueField: string;
    @observable value: TItem | undefined;

    constructor(private readonly params: ConfigurableAutoCompleteStoreParams<TItem>) {
        this.labelField = params.labelField ?? "name";
        this.valueField = params.valueField ?? "id";
        reaction(
            () => this.value,
            async () => {
                if (params.onSelectionChange) {
                    await params.onSelectionChange();
                }
            }
        );
    }

    @action
    async suggest(query: string): Promise<void> {
        if (!this.params.suggest) {
            this.items = [];
            return;
        }

        try {
            const response = await this.params.suggest(query);
            const suggestions = this.getArray(response);
            if (query) {
                const lowerQuery = query.toLowerCase();
                this.items = suggestions.filter((item) => {
                    const label: string = item[this.labelField];
                    const lowerLabel = label.toLowerCase();
                    return lowerLabel.includes(lowerQuery);
                });
            } else {
                this.items = suggestions;
            }
        } catch (e) {
            this.items = [];
        }
    }

    @action
    async clearSuggestions() {
        await this.suggest("");
    }

    private getArray(response: ListResult<TItem> | TItem[] | undefined): TItem[] {
        if (response === undefined) {
            return [];
        } else if (response["items"] !== undefined) {
            return response["items"];
        } else {
            return response as TItem[];
        }
    }
}

import { RemoteUiCrudEditorStore } from "src/stores/common/ContentEditorStore";
import {
    UPanelEconomicEntityTariffCreateArgsDto,
    UPanelEconomicEntityTariffDto,
    ListResult,
    RemoteUiDefinitionDto,
    Result,
} from "src/api";
import { RootStore } from "src/stores/RootStore";
import { ProjectRouteNames } from "src/pages/project/ProjectRoutes";
import { observable } from "mobx";
import { ProjectTariffVersionStore } from "src/stores/project/ProjectTariffVersionStore";

type ProjectTariffRouteArgs = {
    oid: string;
    pid: string;
};

export class ProjectTariffStore extends RemoteUiCrudEditorStore<
    UPanelEconomicEntityTariffDto,
    UPanelEconomicEntityTariffCreateArgsDto,
    ProjectTariffRouteArgs
> {
    @observable versions: ProjectTariffVersionStore;

    constructor(root: RootStore) {
        super(root, ProjectRouteNames.tariffList, ProjectRouteNames.tariffAdd);
        this.versions = new ProjectTariffVersionStore(root);
    }

    getRemoteUi(): Promise<RemoteUiDefinitionDto<UPanelEconomicEntityTariffCreateArgsDto>> {
        return this.root.userRpc.projectTariff.getRemoteUi(this.args.oid, this.args.pid);
    }

    protected async onLoaded(): Promise<void> {
        if (this.selectedItemId === this.dummySelectedItemId) return;
        this.versions.args = {
            ...this.args,
            tariffId: this.selectedItemId,
        };
        await this.versions.refresh();
    }

    protected createNew(args: UPanelEconomicEntityTariffCreateArgsDto): Promise<Result> {
        return this.root.userRpc.projectTariff.create(this.args.oid, this.args.pid, args);
    }

    protected getAll(): Promise<ListResult<UPanelEconomicEntityTariffDto>> {
        return this.root.userRpc.projectTariff.getAll(this.args.oid, this.args.pid, this.skip, this.take);
    }

    protected async getById(id: string): Promise<UPanelEconomicEntityTariffDto | undefined> {
        const response = await this.root.userRpc.projectTariff.getById(this.args.oid, this.args.pid, id);
        return response.success ? response.value : undefined;
    }

    protected update(args: UPanelEconomicEntityTariffCreateArgsDto): Promise<Result> {
        return this.root.userRpc.projectTariff.update(this.args.oid, this.args.pid, this.selectedItemId, args);
    }

    protected deleteById(id: string): Promise<Result> {
        return this.root.userRpc.projectTariff.delete(this.args.oid, this.args.pid, id);
    }
}

import React, { FC } from "react";
import styles from "src/components/Spinner/spinner.module.css";

export type SpinnerSize = "small" | "large";

export type SpinnerVariant = "primary" | "secondary" | "important";

export type SpinnerProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    size?: SpinnerSize;
    variant?: SpinnerVariant;
};

const sizes: { [key in SpinnerSize]: string } = {
    small: styles.spinner__size_small,
    large: styles.spinner__size_large,
};

const variants: { [key in SpinnerVariant]: string } = {
    primary: styles.spinner__variant_primary,
    secondary: styles.spinner__variant_secondary,
    important: styles.spinner__variant_important,
};

export const Spinner: FC<SpinnerProps> = ({ className, size, variant, ...props }) => {
    return (
        <div
            {...props}
            className={`${className} ${styles.spinner} ${variant ? variants[variant] : ""} ${size ? sizes[size] : ""}`}
        />
    );
};

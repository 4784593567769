import { lazy } from "react";

export const AdminOrganizationListPage = lazy(
    () => import("src/pages/admin/AdminOrganization/AdminOrganizationListPage")
);
export const AdminOrganizationEditPage = lazy(
    () => import("src/pages/admin/AdminOrganization/AdminOrganizationEditPage")
);
export const AdminOrganizationSupportPage = lazy(
    () => import("src/pages/admin/AdminOrganization/AdminOrganizationSupportPage")
);
export const AdminUserListPage = lazy(() => import("src/pages/admin/AdminUser/AdminUserListPage"));
export const AdminUserEditPage = lazy(() => import("src/pages/admin/AdminUser/AdminUserEditPage"));
export const AdminUserSupportPage = lazy(() => import("src/pages/admin/AdminUser/AdminUserSupportPage"));
export const AdminBookKeeperConnectionListPage = lazy(
    () => import("src/pages/admin/AdminBookKeeperConnection/AdminBookKeeperConnectionListPage")
);
export const AdminBookKeeperConnectionEditPage = lazy(
    () => import("src/pages/admin/AdminBookKeeperConnection/AdminBookKeeperConnectionEditPage")
);
export const AdminBookKeeperConnectionAddPage = lazy(
    () => import("src/pages/admin/AdminBookKeeperConnection/AdminBookKeeperConnectionAddPage")
);
export const AdminOrganizationBookKeeperConnectionListPage = lazy(
    () => import("src/pages/admin/AdminOrganizationBookKeeperConnection/AdminOrganizationBookKeeperConnectionListPage")
);
export const AdminOrganizationBookKeeperConnectionEditPage = lazy(
    () => import("src/pages/admin/AdminOrganizationBookKeeperConnection/AdminOrganizationBookKeeperConnectionEditPage")
);
export const AdminOrganizationBookKeeperConnectionAddPage = lazy(
    () => import("src/pages/admin/AdminOrganizationBookKeeperConnection/AdminOrganizationBookKeeperConnectionAddPage")
);
export const AdminUPanelApiConnectionListPage = lazy(
    () => import("src/pages/admin/AdminUPanelApiConnection/AdminUPanelApiConnectionListPage")
);
export const AdminUPanelApiConnectionEditPage = lazy(
    () => import("src/pages/admin/AdminUPanelApiConnection/AdminUPanelApiConnectionEditPage")
);
export const AdminUPanelApiConnectionAddPage = lazy(
    () => import("src/pages/admin/AdminUPanelApiConnection/AdminUPanelApiConnectionAddPage")
);
export const AdminConnectableServiceListPage = lazy(
    () => import("src/pages/admin/AdminConnectableService/AdminConnectableServiceListPage")
);
export const AdminConnectableServiceEditPage = lazy(
    () => import("src/pages/admin/AdminConnectableService/AdminConnectableServiceEditPage")
);
export const AdminConnectableServiceAddPage = lazy(
    () => import("src/pages/admin/AdminConnectableService/AdminConnectableServiceAddPage")
);
export const AdminProjectListPage = lazy(() => import("src/pages/admin/AdminProject/AdminProjectListPage"));
export const AdminProjectEditPage = lazy(() => import("src/pages/admin/AdminProject/AdminProjectEditPage"));
export const AdminProjectAddPage = lazy(() => import("src/pages/admin/AdminProject/AdminProjectAddPage"));

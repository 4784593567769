import { RootStore } from "src/stores/RootStore";
import { RemoteUiCrudEditorStore } from "src/stores/common/ContentEditorStore";
import {
    ListResult,
    RemoteUiDefinitionDto,
    Result,
    UPanelAdminProjectCreateArgsDto,
    UPanelAdminProjectDto,
} from "src/api";
import { AdminRouteNames } from "src/pages/admin/AdminRoutes";

type AdminProjectStoreArgs = {
    organizationId: string;
    oid: string;
    pid: string;
};

export class AdminProjectStore extends RemoteUiCrudEditorStore<
    UPanelAdminProjectDto,
    UPanelAdminProjectCreateArgsDto,
    AdminProjectStoreArgs
> {
    constructor(root: RootStore) {
        super(root, AdminRouteNames.projectList, AdminRouteNames.projectAdd);
    }

    protected getAll(): Promise<ListResult<UPanelAdminProjectDto>> {
        const oid = this.args.organizationId;
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminProject.getAll(oid, this.skip, this.take);
    }

    protected getById(id: string): Promise<UPanelAdminProjectDto> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminProject.getById(id);
    }

    protected createNew(args: UPanelAdminProjectCreateArgsDto): Promise<Result> {
        const oid = this.args.organizationId;
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminProject.create(oid, args);
    }

    protected update(args: UPanelAdminProjectCreateArgsDto): Promise<Result> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminProject.update(this.selectedItemId, args);
    }

    protected deleteById(id: string): Promise<Result> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminProject.delete(id);
    }

    protected getRemoteUi(): Promise<RemoteUiDefinitionDto<UPanelAdminProjectCreateArgsDto>> {
        const oid = this.args.organizationId;
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminProject.getRemoteUi(oid);
    }
}

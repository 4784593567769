import { RemoteUiCrudEditorStore } from "src/stores/common/ContentEditorStore";
import {
    UPanelEconomicEntityCurrencyTypeCreateArgsDto,
    UPanelEconomicEntityCurrencyTypeDto,
    ListResult,
    RemoteUiDefinitionDto,
    Result,
} from "src/api";
import { RootStore } from "src/stores/RootStore";
import { ProjectRouteNames } from "src/pages/project/ProjectRoutes";

type ProjectCurrencyRouteArgs = {
    oid: string;
    pid: string;
};

export class ProjectCurrencyStore extends RemoteUiCrudEditorStore<
    UPanelEconomicEntityCurrencyTypeDto,
    UPanelEconomicEntityCurrencyTypeCreateArgsDto,
    ProjectCurrencyRouteArgs
> {
    constructor(root: RootStore) {
        super(root, ProjectRouteNames.currencyList, ProjectRouteNames.currencyAdd);
    }

    getRemoteUi(): Promise<RemoteUiDefinitionDto<UPanelEconomicEntityCurrencyTypeCreateArgsDto>> {
        return this.root.userRpc.projectCurrencyType.getRemoteUi(this.args.oid, this.args.pid);
    }

    protected createNew(args: UPanelEconomicEntityCurrencyTypeCreateArgsDto): Promise<Result> {
        return this.root.userRpc.projectCurrencyType.create(this.args.oid, this.args.pid, args);
    }

    protected getAll(): Promise<ListResult<UPanelEconomicEntityCurrencyTypeDto>> {
        return this.root.userRpc.projectCurrencyType.getAll(this.args.oid, this.args.pid, this.skip, this.take);
    }

    protected async getById(id: string): Promise<UPanelEconomicEntityCurrencyTypeDto | undefined> {
        const response = await this.root.userRpc.projectCurrencyType.getById(this.args.oid, this.args.pid, id);
        return response.success ? response.value : undefined;
    }

    protected update(args: UPanelEconomicEntityCurrencyTypeCreateArgsDto): Promise<Result> {
        return this.root.userRpc.projectCurrencyType.update(this.args.oid, this.args.pid, this.selectedItemId!, args);
    }

    protected deleteById(id: string): Promise<Result> {
        return this.root.userRpc.projectCurrencyType.delete(this.args.oid, this.args.pid, id);
    }
}

import { RootStore } from "src/stores/RootStore";
import { action, computed, observable } from "mobx";
import { CustomHistoryAdapter } from "src/routing/CustomHistoryAdapter";
import { StringMap } from "mobx-state-router/dist/types/router-store";
import { ConnectableServiceInfoDto, ResultT } from "src/api";

export type AppStoreRouteArgs = {
    appId: string;
    path: string[];
    query: StringMap;
};

export type AppStoreApi = {
    isConnected: (serviceId: number) => Promise<ResultT<boolean>>;
    connect: (serviceId: number) => Promise<ResultT<string>>;
    getToken: (serviceId: number) => Promise<ResultT<string>>;
    getService: (serviceId: number) => Promise<ConnectableServiceInfoDto>;
};

export class AppStoreBase<TRouteArgs extends AppStoreRouteArgs> {
    @observable args?: TRouteArgs;
    @observable isConnected?: boolean;
    @observable serviceAccessToken?: string;
    @observable service?: ConnectableServiceInfoDto;
    @observable errorOccured: boolean = false;

    constructor(private readonly history: CustomHistoryAdapter, private readonly api: AppStoreApi) {}

    @computed get showEmbeddableView(): boolean {
        return !!this.args?.appId && !!this.isConnected && !!this.serviceAccessToken;
    }

    @computed get showActivationScreen(): boolean {
        return !!this.args?.appId && this.isConnected === false;
    }

    @computed get canConnect(): boolean {
        return !!this.service && !this.service.isConnected && this.service.isAvailableToAll;
    }

    @computed get appId(): string {
        const id = this.args?.appId;
        if (!id) throw new Error("AppId is not defined.");
        return id;
    }

    @computed get initialResource(): string {
        if (this.args) {
            const path = this.args.path.reduce((previous, current) => previous + "/" + current, "");
            const queryPairs = Object.keys(this.args.query)
                .map((key) => `${key}=${this.args?.query[key]}`)
                .join("&");
            return queryPairs.length ? path + "?" + queryPairs : path;
        } else {
            return "/";
        }
    }

    @computed get historyAdapter(): CustomHistoryAdapter {
        return this.history;
    }

    @action async connect(): Promise<void> {
        if (this.args?.appId) {
            this.isConnected = undefined;
            const serviceId = Number(this.args.appId);
            const connection = await this.api.connect(serviceId);
            this.serviceAccessToken = connection.value;
            this.isConnected = true;
        }
    }

    @action async reload(): Promise<void> {
        await this.load(this.args!);
    }

    @action async load(args: TRouteArgs) {
        if (this.args?.appId === args.appId) {
            return;
        }

        this.errorOccured = false;
        this.args = args;
        this.isConnected = undefined;
        this.serviceAccessToken = undefined;
        const serviceId = Number(this.args.appId);
        try {
            this.service = await this.api.getService(serviceId);
            const connected = await this.api.isConnected(serviceId);
            if (connected.value) {
                const token = await this.api.getToken(serviceId);
                this.serviceAccessToken = token.value;
                this.isConnected = true;
            } else {
                this.isConnected = false;
            }
        } catch (e) {
            console.warn(e);
            this.errorOccured = true;
        }
    }
}

export class UserAppStore extends AppStoreBase<AppStoreRouteArgs> {
    @observable args?: AppStoreRouteArgs;

    constructor(root: RootStore) {
        super(root.historyAdapter, {
            getToken: (serviceId) => root.userRpc.userConnectableService.getToken(serviceId),
            isConnected: (serviceId) => root.userRpc.userConnectableService.isConnected(serviceId),
            connect: (serviceId) => root.userRpc.userConnectableService.connect(serviceId),
            getService: (serviceId) => root.userRpc.userConnectableService.getService(serviceId),
        });
    }
}

import { RootStore } from "src/stores/RootStore";
import { action } from "mobx";
import { RouterState } from "mobx-state-router";
import { UserRouteNames } from "src/pages/user/UserRoutes";

export class UserLoginRedirectStore {
    constructor(private readonly root: RootStore) {}

    @action load(to: RouterState): void {
        const url = window.location.toString();
        const state = to.queryParams["state"];
        this.completeLogin(url, state);
    }

    private async completeLogin(url: string, state: string) {
        const response = await this.root.userRpc.userLogin.completeLogin(url, state);
        this.root.userRpc.setUserToken(response.value);
        await this.root.routerStore.goTo(UserRouteNames.organizations);
    }
}

import { lazy } from "react";

export const AnonNotFoundPage = lazy(() => import("src/pages/anon/AnonNotFoundPage"));
export const UserAppPage = lazy(() => import("src/pages/user/UserApp/UserAppPage"));
export const UserLoginPage = lazy(() => import("src/pages/user/UserLogin/UserLoginPage"));
export const UserLoginRedirectPage = lazy(() => import("src/pages/user/UserLoginRedirect/UserLoginRedirectPage"));
export const UserDocumentListPage = lazy(() => import("src/pages/user/UserDocumentList/UserDocumentListPage"));
export const UserSupportPage = lazy(() => import("src/pages/user/UserSupport/UserSupportPage"));
export const UserOrganizationListPage = lazy(
    () => import("src/pages/user/UserOrganizationList/UserOrganizationListPage")
);

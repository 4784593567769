import { RootStore } from "src/stores/RootStore";
import { RemoteUiCrudEditorStore } from "src/stores/common/ContentEditorStore";
import {
    ListResult,
    RemoteUiDefinitionDto,
    Result,
    UPanelConnectableServiceCreateArgsDto,
    UPanelConnectableServiceDto,
} from "src/api";
import { AdminRouteNames } from "src/pages/admin/AdminRoutes";

type AdminConnectableServiceStoreArgs = {
    oid: string;
    pid: string;
};

export class AdminConnectableServiceStore extends RemoteUiCrudEditorStore<
    UPanelConnectableServiceDto,
    UPanelConnectableServiceCreateArgsDto,
    AdminConnectableServiceStoreArgs
> {
    constructor(root: RootStore) {
        super(root, AdminRouteNames.connectableServiceList, AdminRouteNames.connectableServiceAdd);
    }

    protected getAll(): Promise<ListResult<UPanelConnectableServiceDto>> {
        return this.root.userRpc
            .createAdminInstanceRpc(this.args)
            .adminConnectableService.getAll("", this.skip, this.take);
    }

    protected getById(id: number): Promise<UPanelConnectableServiceDto> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminConnectableService.getById(id);
    }

    protected createNew(args: UPanelConnectableServiceCreateArgsDto): Promise<Result> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminConnectableService.create(args);
    }

    protected update(args: UPanelConnectableServiceCreateArgsDto): Promise<Result> {
        return this.root.userRpc
            .createAdminInstanceRpc(this.args)
            .adminConnectableService.update(parseInt(this.selectedItemId), args);
    }

    protected deleteById(id: number): Promise<Result> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminConnectableService.delete(id);
    }

    protected getRemoteUi(): Promise<RemoteUiDefinitionDto<UPanelConnectableServiceCreateArgsDto>> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminConnectableService.getRemoteUi();
    }
}

import * as React from "react";
import { RouterView } from "mobx-state-router";
import { useRootStore } from "src/utilities";
import { useObserver } from "mobx-react";
import { SuspensePlaceholder } from "src/components/SuspensePlaceholder/SuspensePlaceholder";
import { AnonViewMap } from "src/pages/user/UserRoutes";

export const AnonShell = () => {
    const { routerStore } = useRootStore().rootStore;
    return useObserver(() => (
        <SuspensePlaceholder>
            <RouterView routerStore={routerStore} viewMap={AnonViewMap} />
        </SuspensePlaceholder>
    ));
};

import { RemoteUiCrudEditorStore } from "src/stores/common/ContentEditorStore";
import {
    ListResult,
    RemoteUiDefinitionDto,
    Result,
    UPanelOrganizationBookKeeperConnectionCreateArgsDto,
    UPanelOrganizationBookKeeperConnectionDto,
} from "src/api";
import { RootStore } from "src/stores/RootStore";
import { AdminRouteNames } from "src/pages/admin/AdminRoutes";

type AdminOrganizationBookKeeperConnectionStoreArgs = {
    oid: string;
    pid: string;
};

export class AdminOrganizationBookKeeperConnectionStore extends RemoteUiCrudEditorStore<
    UPanelOrganizationBookKeeperConnectionDto,
    UPanelOrganizationBookKeeperConnectionCreateArgsDto,
    AdminOrganizationBookKeeperConnectionStoreArgs
> {
    constructor(root: RootStore) {
        super(
            root,
            AdminRouteNames.organizationBookKeeperConnectionList,
            AdminRouteNames.organizationBookKeeperConnectionAdd
        );
    }

    protected getAll(): Promise<ListResult<UPanelOrganizationBookKeeperConnectionDto>> {
        return this.root.userRpc
            .createAdminInstanceRpc(this.args)
            .adminOrganizationBookKeeperConnection.getAll(this.skip, this.take);
    }

    protected getById(id: string): Promise<UPanelOrganizationBookKeeperConnectionDto> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminOrganizationBookKeeperConnection.getById(id);
    }

    protected createNew(args: UPanelOrganizationBookKeeperConnectionCreateArgsDto): Promise<Result> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminOrganizationBookKeeperConnection.create(args);
    }

    protected update(args: UPanelOrganizationBookKeeperConnectionCreateArgsDto): Promise<Result> {
        return this.root.userRpc
            .createAdminInstanceRpc(this.args)
            .adminOrganizationBookKeeperConnection.update(this.selectedItemId, args);
    }

    protected deleteById(id: string): Promise<Result> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminOrganizationBookKeeperConnection.delete(id);
    }

    protected getRemoteUi(): Promise<RemoteUiDefinitionDto<UPanelOrganizationBookKeeperConnectionCreateArgsDto>> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminOrganizationBookKeeperConnection.getRemoteUi();
    }
}

import { action, computed, observable } from "mobx";
import { Loadable } from "src/stores/common/LoadableStore";

type PageSizeOption = {
    label: string;
    value: number;
};

export interface IReactTableStore<T> {
    totalPages: number;
    pageSize: number;
    isLoading: boolean;
    items: T[];

    setPageSize(size: number, page: number): void;
}

export abstract class ReactTableStore<T, TArgs extends object = {}>
    extends Loadable<TArgs>
    implements IReactTableStore<T> {
    @observable args = {} as TArgs;
    @observable page: number;
    @observable pageSize: number;
    //TODO Translate this if important
    @observable pageSizeOptions: PageSizeOption[] = [
        { label: "Показывать по 20", value: 20 },
        { label: "Показывать по 40", value: 40 },
        { label: "Показывать по 80", value: 80 },
        { label: "Показывать по 200", value: 200 },
    ];
    @observable totalPages: number;
    @observable totalItemsCount: number;
    @observable items: T[];
    @observable search: string = "";
    @observable showPageSizeOptions = true;

    @computed get skip() {
        return this.page * this.pageSize;
    }

    @computed get take() {
        return this.pageSize;
    }

    @computed get pageSizeOption() {
        return this.pageSizeOptions.find((x) => x.value === this.pageSize) || this.pageSizeOptions[0];
    }

    public abstract refresh(): Promise<void>;

    @action async load(args?: TArgs): Promise<void> {
        if (args) {
            this.args = args;
        }
        this.page = 0;
        this.pageSize = 20;
        this.items = [];
        this.totalPages = 0;
        this.totalItemsCount = 0;
        this.search = "";
        await this.refresh();
    }

    protected constructor() {
        super();
        this.page = 0;
        this.pageSize = 20;
        this.items = [];
        this.totalPages = 0;
        this.totalItemsCount = 0;
    }

    @action fillItems(items: T[], totalCount: number) {
        this.totalItemsCount = totalCount;
        this.items = items;
        this.totalPages = Math.ceil(totalCount / this.pageSize);
        this.isLoaded = true;
    }

    @action setPageSize(size: any, page: any) {
        this.pageSize = size;
        this.page = page;
        return this.refresh();
    }

    @action setSearch(search: string) {
        this.search = search;
        return this.setPageSize(this.pageSize, 0);
    }
}

class LiveReloadHelper {
    isReloading = false;
    private onReload: () => Promise<void>;
    constructor(onReload: () => Promise<void>) {
        this.onReload = onReload;
    }

    reload(): void {
        if (this.isReloading) return;
        this.onReload()
            .catch((e) => {
                this.isReloading = false;
                console.log(e);
            })
            .then(() => {
                this.isReloading = false;
            });
    }
}

export abstract class LiveReloadableReactTableStore<T> extends ReactTableStore<T> {
    _reloadHelper: LiveReloadHelper;

    constructor(onLiveReload: () => Promise<void>) {
        super();
        this._reloadHelper = new LiveReloadHelper(async () => {
            if (this.items && this.items.length > 0) {
                await onLiveReload();
            }
        });
    }

    @action liveReload() {
        this._reloadHelper.reload();
    }
}

import { RootStore } from "src/stores/RootStore";
import { AppStoreBase, AppStoreRouteArgs } from "src/stores/user/UserAppStore";

type OrganizationAppRouteArgs = AppStoreRouteArgs & {
    oid: string;
};

export class OrganizationAppStore extends AppStoreBase<OrganizationAppRouteArgs> {
    constructor(root: RootStore) {
        super(root.historyAdapter, {
            getToken: (serviceId) => root.userRpc.organizationConnectableService.getToken(this.args!.oid, serviceId),
            isConnected: (sid) => root.userRpc.organizationConnectableService.isConnected(this.args!.oid, sid),
            connect: (serviceId) => root.userRpc.organizationConnectableService.connect(this.args!.oid, serviceId),
            getService: (sid) => root.userRpc.organizationConnectableService.getService(this.args!.oid, sid),
        });
    }
}

import React, { FC, Suspense } from "react";
import { Spinner } from "src/components/Spinner/Spinner";
import styles from "src/components/SuspensePlaceholder/suspensePlaceholder.module.css";
import { LocalizedText } from "src/locales/LocalizedText";
import { Fade } from "reactstrap";

const Fallback = () => {
    return (
        <Fade className={styles.container}>
            <Spinner className={styles.loading} size="large" variant="important" />
            <div className={styles.description}>
                <LocalizedText id={"fallback"} />
            </div>
        </Fade>
    );
};

export const SuspensePlaceholder: FC = ({ children }) => <Suspense fallback={Fallback()}>{children}</Suspense>;

import { Route } from "mobx-state-router";
import { convertRoutes } from "src/routing/route";
import {
    UserAllowOnlyFilledProfilesHook,
    UserAuthorizedOnlyHook,
    UserEnsureProfileLoadedHook,
} from "src/pages/user/UserRoutes";
import React from "react";
import {
    AccountingAccountEditPage,
    AccountingAccountListPage,
    AccountingContractEditPage,
    AccountingContractListPage,
    AccountingServiceEditPage,
    AccountingServiceListPage,
    AccountingServiceProvisionListPage,
    AccountingSubAccountEditPage,
    AccountingSubAccountListPage,
    AccountingTransactionEditPage,
    AccountingTransactionListPage,
} from "src/pages/accounting/AccountingLazyRoutes";
import { OrganizationEnsureLoaded } from "src/pages/org/OrganizationRoutes";

export enum AccountingRouteNames {
    accountEdit = "accounting-account-edit",
    accountList = "accounting-account-list",
    subAccountEdit = "accounting-sub-account-edit",
    subAccountList = "accounting-sub-account-list",
    transactionEdit = "accounting-transaction-edit",
    transactionList = "accounting-transaction-list",
    serviceProvisionList = "accounting-service-provision-list",
    serviceList = "accounting-service-list",
    serviceEdit = "accounting-service-edit",
    contractList = "accounting-contract-list",
    contractEdit = "accounting-contract-edit",
}

export const AccountingViewMap = {
    [AccountingRouteNames.accountList]: <AccountingAccountListPage />,
    [AccountingRouteNames.accountEdit]: <AccountingAccountEditPage />,
    [AccountingRouteNames.subAccountList]: <AccountingSubAccountListPage />,
    [AccountingRouteNames.subAccountEdit]: <AccountingSubAccountEditPage />,
    [AccountingRouteNames.transactionEdit]: <AccountingTransactionEditPage />,
    [AccountingRouteNames.transactionList]: <AccountingTransactionListPage />,
    [AccountingRouteNames.serviceProvisionList]: <AccountingServiceProvisionListPage />,
    [AccountingRouteNames.serviceList]: <AccountingServiceListPage />,
    [AccountingRouteNames.serviceEdit]: <AccountingServiceEditPage />,
    [AccountingRouteNames.contractList]: <AccountingContractListPage />,
    [AccountingRouteNames.contractEdit]: <AccountingContractEditPage />,
};

const AccountingContractRoutes: Route[] = convertRoutes([
    {
        pattern: "/accounting/:oid/contract",
        name: AccountingRouteNames.contractList,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            UserAllowOnlyFilledProfilesHook,
            OrganizationEnsureLoaded,
        ],
        onEnter: (root, to) =>
            root.accountingContractStore.load({
                oid: to.params["oid"],
            }),
    },
    {
        pattern: "/accounting/:oid/contract/:contractId",
        name: AccountingRouteNames.contractEdit,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            UserAllowOnlyFilledProfilesHook,
            OrganizationEnsureLoaded,
        ],
        onEnter: (root, to) =>
            root.accountingContractStore.loadWithEditor(to.params["contractId"], {
                oid: to.params["oid"],
            }),
    },
]);

const AccountingServiceRoutes: Route[] = convertRoutes([
    {
        pattern: "/accounting/:oid/service",
        name: AccountingRouteNames.serviceList,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            UserAllowOnlyFilledProfilesHook,
            OrganizationEnsureLoaded,
        ],
        onEnter: (root, to) =>
            root.accountingServiceStore.load({
                oid: to.params["oid"],
            }),
    },
    {
        pattern: "/accounting/:oid/service/:serviceId",
        name: AccountingRouteNames.serviceEdit,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            UserAllowOnlyFilledProfilesHook,
            OrganizationEnsureLoaded,
        ],
        onEnter: (root, to) =>
            root.accountingServiceStore.loadWithEditor(to.params["serviceId"], {
                oid: to.params["oid"],
            }),
    },
]);

const AccountingAccountRoutes: Route[] = convertRoutes([
    {
        pattern: "/accounting/:oid/account",
        name: AccountingRouteNames.accountList,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            UserAllowOnlyFilledProfilesHook,
            OrganizationEnsureLoaded,
        ],
        onEnter: (root, to) =>
            root.accountingAccountStore.load({
                oid: to.params["oid"],
            }),
    },
    {
        pattern: "/accounting/:oid/account/:accountId",
        name: AccountingRouteNames.accountEdit,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            UserAllowOnlyFilledProfilesHook,
            OrganizationEnsureLoaded,
        ],
        onEnter: (root, to) =>
            root.accountingAccountStore.loadWithEditor(to.params["accountId"], {
                oid: to.params["oid"],
            }),
    },
]);

const AccountingSubAccountRoutes: Route[] = convertRoutes([
    {
        pattern: "/accounting/:oid/account/:accountId/sub-account",
        name: AccountingRouteNames.subAccountList,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            UserAllowOnlyFilledProfilesHook,
            OrganizationEnsureLoaded,
        ],
        onEnter: (root, to) =>
            root.accountingSubAccountStore.load({
                oid: to.params["oid"],
                accountId: to.params["accountId"],
            }),
    },
    {
        pattern: "/accounting/:oid/account/:accountId/sub-account/:subAccountId",
        name: AccountingRouteNames.subAccountEdit,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            UserAllowOnlyFilledProfilesHook,
            OrganizationEnsureLoaded,
        ],
        onEnter: (root, to) =>
            root.accountingSubAccountStore.loadWithEditor(to.params["subAccountId"], {
                oid: to.params["oid"],
                accountId: to.params["accountId"],
            }),
    },
]);

const AccountingTransactionRoutes: Route[] = convertRoutes([
    {
        pattern: "/accounting/:oid/account/:accountId/sub-account/:subAccountId/transaction",
        name: AccountingRouteNames.transactionList,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            UserAllowOnlyFilledProfilesHook,
            OrganizationEnsureLoaded,
        ],
        onEnter: (root, to) =>
            root.accountingTransactionStore.load({
                oid: to.params["oid"],
                accountId: to.params["accountId"],
                subAccountId: to.params["subAccountId"],
            }),
    },
    {
        pattern: "/accounting/:oid/account/:accountId/sub-account/:subAccountId/transaction/:transactionId",
        name: AccountingRouteNames.transactionEdit,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            UserAllowOnlyFilledProfilesHook,
            OrganizationEnsureLoaded,
        ],
        onEnter: (root, to) =>
            root.accountingTransactionStore.loadWithEditor(to.params["transactionId"], {
                oid: to.params["oid"],
                accountId: to.params["accountId"],
                subAccountId: to.params["subAccountId"],
            }),
    },
]);

const AccountingServiceProvisionRoutes: Route[] = convertRoutes([
    {
        pattern: "/accounting/:oid/service-provision/",
        name: AccountingRouteNames.serviceProvisionList,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            UserAllowOnlyFilledProfilesHook,
            OrganizationEnsureLoaded,
        ],
        onEnter: (root, to) =>
            root.accountingServiceProvisionStore.load({
                oid: to.params["oid"],
            }),
    },
]);

export const AccountingRoutes = [
    ...AccountingAccountRoutes,
    ...AccountingSubAccountRoutes,
    ...AccountingServiceProvisionRoutes,
    ...AccountingServiceRoutes,
    ...AccountingTransactionRoutes,
    ...AccountingContractRoutes,
];

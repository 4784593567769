import { RootStore } from "src/stores/RootStore";
import { action, observable } from "mobx";
import { convertFileToBytes } from "src/utilities";
import { DocumentDto } from "src/api";
import { DocumentSearchStore, DocumentUploadStore } from "src/components/DocumentList/DocumentListStore";
import { ReactTableStore } from "src/stores/common/ReactTableStore";

export class UserDocumentUploadStore extends DocumentUploadStore {
    constructor(private readonly root: RootStore) {
        super();
    }

    @action async performUpload(): Promise<void> {
        const file = this.file!;
        const extension = file.name.split(".").pop()!;
        const bytes = await convertFileToBytes(file);
        await this.root.userRpc.userDocument.create(this.title, extension, bytes);
        await this.root.userDocumentListStore.refresh();
    }
}

export class UserDocumentSearchStore extends DocumentSearchStore {
    constructor(private readonly root: RootStore) {
        super();
    }

    @action async performSearch(): Promise<void> {
        this.root.userDocumentListStore.page = 0;
        await this.root.userDocumentListStore.refresh();
    }
}

export class UserDocumentListStore extends ReactTableStore<DocumentDto> {
    @observable searchStore: DocumentSearchStore;
    @observable uploadStore: DocumentUploadStore;

    constructor(private readonly root: RootStore) {
        super();
        this.searchStore = new UserDocumentSearchStore(root);
        this.uploadStore = new UserDocumentUploadStore(root);
    }

    @action async refresh(): Promise<void> {
        const documents = await this.root.userRpc.userDocument.getAll(this.skip, this.take, this.searchStore.query);
        this.fillItems(documents.items, documents.totalCount);
    }
}

import React from "react";
import {
    AdminBookKeeperConnectionAddPage,
    AdminBookKeeperConnectionEditPage,
    AdminBookKeeperConnectionListPage,
    AdminConnectableServiceAddPage,
    AdminConnectableServiceEditPage,
    AdminConnectableServiceListPage,
    AdminOrganizationBookKeeperConnectionAddPage,
    AdminOrganizationBookKeeperConnectionEditPage,
    AdminOrganizationBookKeeperConnectionListPage,
    AdminOrganizationEditPage,
    AdminOrganizationListPage,
    AdminOrganizationSupportPage,
    AdminProjectAddPage,
    AdminProjectEditPage,
    AdminProjectListPage,
    AdminUPanelApiConnectionAddPage,
    AdminUPanelApiConnectionEditPage,
    AdminUPanelApiConnectionListPage,
    AdminUserEditPage,
    AdminUserListPage,
    AdminUserSupportPage,
} from "src/pages/admin/AdminLazyRoutes";
import { Route } from "mobx-state-router";
import { convertRoutes } from "src/routing/route";
import { UserAuthorizedOnlyHook, UserEnsureProfileLoadedHook } from "src/pages/user/UserRoutes";
import { ProjectEnsureConnectedHook, ProjectEnsureLoadedHook } from "src/pages/project/ProjectRoutes";

export enum AdminRouteNames {
    userList = "admin-user-list",
    userEdit = "admin-user-edit",
    userSupport = "admin-user-support",
    organizationList = "admin-organization-list",
    organizationEdit = "admin-organization-edit",
    organizationSupport = "admin-organization-support",
    bookKeeperConnectionList = "admin-bkc-list",
    bookKeeperConnectionEdit = "admin-bkc-edit",
    bookKeeperConnectionAdd = "admin-bkc-add",
    organizationBookKeeperConnectionList = "admin-obkc-list",
    organizationBookKeeperConnectionEdit = "admin-obkc-edit",
    organizationBookKeeperConnectionAdd = "admin-obkc-add",
    uPanelApiConnectionList = "admin-upanel-list",
    uPanelApiConnectionEdit = "admin-upanel-edit",
    uPanelApiConnectionAdd = "admin-upanel-add",
    connectableServiceList = "admin-cs-list",
    connectableServiceEdit = "admin-cs-edit",
    connectableServiceAdd = "admin-cs-add",
    projectList = "admin-project-list",
    projectEdit = "admin-project-edit",
    projectAdd = "admin-project-add",
}

export const AdminViewMap = {
    [AdminRouteNames.organizationList]: <AdminOrganizationListPage />,
    [AdminRouteNames.organizationEdit]: <AdminOrganizationEditPage />,
    [AdminRouteNames.organizationSupport]: <AdminOrganizationSupportPage />,
    [AdminRouteNames.userList]: <AdminUserListPage />,
    [AdminRouteNames.userEdit]: <AdminUserEditPage />,
    [AdminRouteNames.userSupport]: <AdminUserSupportPage />,
    [AdminRouteNames.bookKeeperConnectionList]: <AdminBookKeeperConnectionListPage />,
    [AdminRouteNames.bookKeeperConnectionEdit]: <AdminBookKeeperConnectionEditPage />,
    [AdminRouteNames.bookKeeperConnectionAdd]: <AdminBookKeeperConnectionAddPage />,
    [AdminRouteNames.organizationBookKeeperConnectionList]: <AdminOrganizationBookKeeperConnectionListPage />,
    [AdminRouteNames.organizationBookKeeperConnectionEdit]: <AdminOrganizationBookKeeperConnectionEditPage />,
    [AdminRouteNames.organizationBookKeeperConnectionAdd]: <AdminOrganizationBookKeeperConnectionAddPage />,
    [AdminRouteNames.uPanelApiConnectionList]: <AdminUPanelApiConnectionListPage />,
    [AdminRouteNames.uPanelApiConnectionEdit]: <AdminUPanelApiConnectionEditPage />,
    [AdminRouteNames.uPanelApiConnectionAdd]: <AdminUPanelApiConnectionAddPage />,
    [AdminRouteNames.connectableServiceList]: <AdminConnectableServiceListPage />,
    [AdminRouteNames.connectableServiceEdit]: <AdminConnectableServiceEditPage />,
    [AdminRouteNames.connectableServiceAdd]: <AdminConnectableServiceAddPage />,
    [AdminRouteNames.projectList]: <AdminProjectListPage />,
    [AdminRouteNames.projectEdit]: <AdminProjectEditPage />,
    [AdminRouteNames.projectAdd]: <AdminProjectAddPage />,
};

const AdminUserRoutes: Route[] = convertRoutes([
    {
        pattern: "/organization/:oid/project/:pid/admin/user",
        name: AdminRouteNames.userList,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            ProjectEnsureLoadedHook,
            ProjectEnsureConnectedHook,
        ],
        onEnter: async (root, to) =>
            root.adminUserStore.load({
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/admin/user/:userId",
        name: AdminRouteNames.userEdit,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            ProjectEnsureLoadedHook,
            ProjectEnsureConnectedHook,
        ],
        onEnter: async (root, to) =>
            root.adminUserStore.loadWithEditor(to.params["userId"], {
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/admin/user/:uid/support",
        name: AdminRouteNames.userSupport,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            ProjectEnsureLoadedHook,
            ProjectEnsureConnectedHook,
        ],
        onEnter: async (root, to) =>
            root.adminUserSupportStore.load({
                oid: to.params["oid"],
                pid: to.params["pid"],
                uid: parseInt(to.params["uid"]),
            }),
    }
]);

const AdminProjectRoutes: Route[] = convertRoutes([
    {
        pattern: "/organization/:oid/project/:pid/admin/organization/:organizationId/project",
        name: AdminRouteNames.projectList,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            ProjectEnsureLoadedHook,
            ProjectEnsureConnectedHook,
        ],
        onEnter: async (root, to) =>
            root.adminProjectStore.load({
                organizationId: to.params["organizationId"],
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/admin/organization/:organizationId/project/:projectId",
        name: AdminRouteNames.projectEdit,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            ProjectEnsureLoadedHook,
            ProjectEnsureConnectedHook,
        ],
        onEnter: async (root, to) =>
            root.adminProjectStore.loadWithEditor(to.params["projectId"], {
                organizationId: to.params["organizationId"],
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/admin/organization/:organizationId/project/add",
        name: AdminRouteNames.projectAdd,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            ProjectEnsureLoadedHook,
            ProjectEnsureConnectedHook,
        ],
        onEnter: async (root, to) =>
            root.adminProjectStore.load({
                organizationId: to.params["organizationId"],
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
]);

const AdminOrganizationRoutes: Route[] = convertRoutes([
    {
        pattern: "/organization/:oid/project/:pid/admin/organization",
        name: AdminRouteNames.organizationList,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            ProjectEnsureLoadedHook,
            ProjectEnsureConnectedHook,
        ],
        onEnter: async (root, to) =>
            root.adminOrganizationStore.load({
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/admin/organization/:organizationId",
        name: AdminRouteNames.organizationEdit,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            ProjectEnsureLoadedHook,
            ProjectEnsureConnectedHook,
        ],
        onEnter: async (root, to) =>
            root.adminOrganizationStore.loadWithEditor(to.params["organizationId"], {
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/admin/organization/:ooid/support",
        name: AdminRouteNames.organizationSupport,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            ProjectEnsureLoadedHook,
            ProjectEnsureConnectedHook,
        ],
        onEnter: async (root, to) =>
            root.adminOrganizationSupportStore.load({
                oid: to.params["oid"],
                pid: to.params["pid"],
                ooid: to.params["ooid"],
            }),
    }
]);

const AdminConnectableServiceRoutes: Route[] = convertRoutes([
    {
        pattern: "/organization/:oid/project/:pid/admin/cs",
        name: AdminRouteNames.connectableServiceList,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            ProjectEnsureLoadedHook,
            ProjectEnsureConnectedHook,
        ],
        onEnter: async (root, to) =>
            root.adminConnectableServiceStore.load({
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/admin/cs/:sid",
        name: AdminRouteNames.connectableServiceEdit,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            ProjectEnsureLoadedHook,
            ProjectEnsureConnectedHook,
        ],
        onEnter: async (root, to) =>
            root.adminConnectableServiceStore.loadWithEditor(to.params["sid"], {
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/admin/cs/add",
        name: AdminRouteNames.connectableServiceAdd,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            ProjectEnsureLoadedHook,
            ProjectEnsureConnectedHook,
        ],
        onEnter: async (root, to) =>
            root.adminConnectableServiceStore.load({
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
]);

const AdminUPanelApiConnectionRoutes: Route[] = convertRoutes([
    {
        pattern: "/organization/:oid/project/:pid/admin/up",
        name: AdminRouteNames.uPanelApiConnectionList,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            ProjectEnsureLoadedHook,
            ProjectEnsureConnectedHook,
        ],
        onEnter: async (root, to) =>
            root.adminUPanelApiConnectionStore.load({
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/admin/up/:cid",
        name: AdminRouteNames.uPanelApiConnectionEdit,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            ProjectEnsureLoadedHook,
            ProjectEnsureConnectedHook,
        ],
        onEnter: async (root, to) =>
            root.adminUPanelApiConnectionStore.loadWithEditor(to.params["cid"], {
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/admin/up/add",
        name: AdminRouteNames.uPanelApiConnectionAdd,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            ProjectEnsureLoadedHook,
            ProjectEnsureConnectedHook,
        ],
        onEnter: async (root, to) =>
            root.adminUPanelApiConnectionStore.load({
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
]);

const AdminBookKeeperConnectionRoutes: Route[] = convertRoutes([
    {
        pattern: "/organization/:oid/project/:pid/admin/bkc",
        name: AdminRouteNames.bookKeeperConnectionList,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            ProjectEnsureLoadedHook,
            ProjectEnsureConnectedHook,
        ],
        onEnter: async (root, to) =>
            root.adminBookKeeperConnectionStore.load({
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/admin/bkc/:bkcId",
        name: AdminRouteNames.bookKeeperConnectionEdit,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            ProjectEnsureLoadedHook,
            ProjectEnsureConnectedHook,
        ],
        onEnter: async (root, to) =>
            root.adminBookKeeperConnectionStore.loadWithEditor(to.params["bkcId"], {
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/admin/bkc/add",
        name: AdminRouteNames.bookKeeperConnectionAdd,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            ProjectEnsureLoadedHook,
            ProjectEnsureConnectedHook,
        ],
        onEnter: async (root, to) =>
            root.adminBookKeeperConnectionStore.load({
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
]);

const AdminOrganizationBookKeeperConnectionRoutes: Route[] = convertRoutes([
    {
        pattern: "/organization/:oid/project/:pid/admin/obkc",
        name: AdminRouteNames.organizationBookKeeperConnectionList,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            ProjectEnsureLoadedHook,
            ProjectEnsureConnectedHook,
        ],
        onEnter: async (root, to) =>
            root.adminOrganizationBookKeeperConnectionStore.load({
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/admin/obkc/:bkcId",
        name: AdminRouteNames.organizationBookKeeperConnectionEdit,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            ProjectEnsureLoadedHook,
            ProjectEnsureConnectedHook,
        ],
        onEnter: async (root, to) =>
            root.adminOrganizationBookKeeperConnectionStore.loadWithEditor(to.params["bkcId"], {
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
    {
        pattern: "/organization/:oid/project/:pid/admin/obkc/add",
        name: AdminRouteNames.organizationBookKeeperConnectionAdd,
        hooks: [
            UserAuthorizedOnlyHook,
            UserEnsureProfileLoadedHook,
            ProjectEnsureLoadedHook,
            ProjectEnsureConnectedHook,
        ],
        onEnter: async (root, to) =>
            root.adminOrganizationBookKeeperConnectionStore.load({
                oid: to.params["oid"],
                pid: to.params["pid"],
            }),
    },
]);

export const AdminRoutes: Route[] = [
    ...AdminUserRoutes,
    ...AdminOrganizationRoutes,
    ...AdminBookKeeperConnectionRoutes,
    ...AdminOrganizationBookKeeperConnectionRoutes,
    ...AdminUPanelApiConnectionRoutes,
    ...AdminConnectableServiceRoutes,
    ...AdminProjectRoutes,
];

import React from "react";
import { Route } from "mobx-state-router";
import { ProjectRoutes } from "src/pages/project/ProjectRoutes";
import { AnonRoutes, UserRoutes } from "src/pages/user/UserRoutes";
import { OrgRoutes } from "src/pages/org/OrganizationRoutes";
import { AdminRoutes } from "src/pages/admin/AdminRoutes";
import { AccountingRoutes } from "src/pages/accounting/AccountingRoutes";

export const Routes: Route[] = [
    ...AnonRoutes,
    ...AccountingRoutes,
    ...UserRoutes,
    ...OrgRoutes,
    ...ProjectRoutes,
    ...AdminRoutes,
];

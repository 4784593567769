import { FC } from "react";
import styles from "./navigation-sidebar.module.css";
import * as React from "react";
import { LangChooser } from "src/components/LangChooser/LangChooser";

export const LocalizedNavigationDelimiter: FC<{ content: string }> = ({ content }) => (
    <div className={styles.navigationDelimiter}>{content}</div>
);

export const NavigationSidebar: FC = ({ children }) => (
    <div className={styles.navigationSideBar}>
        {children}
        <div className={styles.navigationFooter + " mb-3"} style={{ display: "flex", alignItems: "center" }}>
            <div className={`mr-3`}>{`© ${new Date().getFullYear()} UPanel`}</div> <LangChooser />
        </div>
    </div>
);

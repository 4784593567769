import { RootStore } from "src/stores/RootStore";
import { RemoteUiCrudEditorStore } from "src/stores/common/ContentEditorStore";
import {
    ListResult,
    RemoteUiDefinitionDto,
    Result,
    UPanelBookKeeperConnectionCreateArgsDto,
    UPanelBookKeeperConnectionDto,
} from "src/api";
import { AdminRouteNames } from "src/pages/admin/AdminRoutes";

type AdminBookKeeperConnectionStoreArgs = {
    oid: string;
    pid: string;
};

export class AdminBookKeeperConnectionStore extends RemoteUiCrudEditorStore<
    UPanelBookKeeperConnectionDto,
    UPanelBookKeeperConnectionCreateArgsDto,
    AdminBookKeeperConnectionStoreArgs
> {
    constructor(root: RootStore) {
        super(root, AdminRouteNames.bookKeeperConnectionList, AdminRouteNames.bookKeeperConnectionAdd);
    }

    protected getAll(): Promise<ListResult<UPanelBookKeeperConnectionDto>> {
        return this.root.userRpc
            .createAdminInstanceRpc(this.args)
            .adminBookKeeperConnection.getAll(this.skip, this.take);
    }

    protected getById(id: string): Promise<UPanelBookKeeperConnectionDto> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminBookKeeperConnection.getById(id);
    }

    protected createNew(args: UPanelBookKeeperConnectionCreateArgsDto): Promise<Result> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminBookKeeperConnection.create(args);
    }

    protected update(args: UPanelBookKeeperConnectionCreateArgsDto): Promise<Result> {
        return this.root.userRpc
            .createAdminInstanceRpc(this.args)
            .adminBookKeeperConnection.update(this.selectedItemId, args);
    }

    protected deleteById(id: string): Promise<Result> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminBookKeeperConnection.delete(id);
    }

    protected getRemoteUi(): Promise<RemoteUiDefinitionDto<UPanelBookKeeperConnectionCreateArgsDto>> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminBookKeeperConnection.getRemoteUi();
    }
}

import { RemoteUiCrudEditorStore } from "src/stores/common/ContentEditorStore";
import {
    ListResult,
    RemoteUiDefinitionDto,
    Result,
    UPanelEconomicEntityServiceCreateArgsDto,
    UPanelEconomicEntityServiceDto,
} from "src/api";
import { RootStore } from "src/stores/RootStore";
import { AccountingRouteNames } from "src/pages/accounting/AccountingRoutes";

type AccountingServiceStoreArgs = {
    oid: string;
};

export class AccountingServiceStore extends RemoteUiCrudEditorStore<
    UPanelEconomicEntityServiceDto,
    UPanelEconomicEntityServiceCreateArgsDto,
    AccountingServiceStoreArgs
> {
    constructor(root: RootStore) {
        super(root, AccountingRouteNames.serviceList, AccountingRouteNames.serviceList);
    }

    protected getRemoteUi(): Promise<RemoteUiDefinitionDto<UPanelEconomicEntityServiceCreateArgsDto>> {
        return this.root.userRpc.accountingService.getRemoteUi(this.args.oid);
    }

    protected getAll(): Promise<ListResult<UPanelEconomicEntityServiceDto>> {
        return this.root.userRpc.accountingService.getAll(this.args.oid, this.skip, this.take);
    }

    protected async getById(id: string): Promise<UPanelEconomicEntityServiceDto | undefined> {
        const response = await this.root.userRpc.accountingService.getById(this.args.oid, id);
        return response.success ? response.value : undefined;
    }

    protected createNew(args: UPanelEconomicEntityServiceCreateArgsDto): Promise<Result> {
        throw new Error("This method is not implemented.");
    }

    protected update(args: UPanelEconomicEntityServiceCreateArgsDto): Promise<Result> {
        throw new Error("This method is not implemented.");
    }

    protected deleteById(id: string): Promise<Result> {
        throw new Error("This method is not implemented.");
    }
}

import { RootStore } from "src/stores/RootStore";
import { action, observable } from "mobx";

export class UserLoginStore {
    @observable urlObtained: boolean = false;

    constructor(private readonly root: RootStore) {}

    @action async logout(): Promise<void> {
        const token = this.root.userRpc.getUserToken();
        this.root.userRpc.resetStore();
        const logoutUrl = await this.root.userRpc.userLogin.startLogout(token);
        window.location.replace(logoutUrl);
    }

    @action async login(): Promise<void> {
        this.urlObtained = false;
        const startUrl = await this.root.userRpc.userLogin.startLogin();
        this.urlObtained = true;
        window.location.replace(`${startUrl}`);
    }
}

import { ListResult, MessageBalloonDto } from "src/api";
import { action, observable } from "mobx";
import { IsNotEmpty, validate } from "@keroosha/class-validator";
import React from "react";
import { reduceValidationErrorsToErrors } from "src/utilities";
import { ReactTableStore } from "src/stores/common/ReactTableStore";

type SupportStoreErrors = {
    message?: string[];
};

export abstract class SupportStore<RouteArgs extends object = {}> extends ReactTableStore<
    MessageBalloonDto,
    RouteArgs
> {
    @observable
    isBusy: boolean = false;
    @observable
    errors: SupportStoreErrors = {};
    @IsNotEmpty({ message: "error__isNotEmpty__support" })
    @observable
    message: string = "";
    @observable
    bottomRef = React.createRef<HTMLDivElement>();
    private readonly initialPageSize: number = 5;
    private freezeScrolling: boolean = false;

    abstract sendMessage(): Promise<void>;

    abstract loadMessages(): Promise<ListResult<MessageBalloonDto>>;

    @action
    async loadMore(): Promise<void> {
        this.freezeScrolling = true;
        this.pageSize = this.pageSize + this.initialPageSize;
        await this.refresh();
        this.freezeScrolling = false;
    }

    @action
    async send(): Promise<void> {
        const errors = await validate(this);
        if (errors.length !== 0) {
            this.errors = reduceValidationErrorsToErrors(errors);
            return;
        }

        this.isBusy = true;
        await this.sendMessage();
        await this.refresh();
        this.errors = {};
        this.message = "";
        this.isBusy = false;
    }

    @action
    async refresh(): Promise<void> {
        const messages = await this.loadMessages();
        const reordered = messages.items.sort((left, right) => {
            return new Date(left.createdAt).getTime() - new Date(right.createdAt).getTime();
        });
        this.fillItems(reordered, messages.totalCount);
        this.scrollToMessages();
    }

    @action scrollToMessages(): void {
        if (this.freezeScrolling) return;
        this.bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }
}

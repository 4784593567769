import { IsNotEmpty, validate } from "@keroosha/class-validator";
import { reduceValidationErrorsToErrors } from "src/utilities";
import { action, observable } from "mobx";
import { ReactTableStore } from "src/stores/common/ReactTableStore";

export type DocumentSearchStoreErrors = {
    query?: string[];
};

export abstract class DocumentSearchStore {
    @observable isBusy: boolean = false;
    @observable errors: DocumentSearchStoreErrors = {};
    @IsNotEmpty({ message: "error__isNotEmpty__search" }) @observable query: string = "";

    abstract performSearch(): Promise<void>;

    @action async search(): Promise<void> {
        const errors = await validate(this);
        if (errors.length !== 0) {
            this.errors = reduceValidationErrorsToErrors(errors);
            return;
        }

        this.isBusy = true;
        this.errors = {};
        await this.performSearch();
        this.isBusy = false;
    }

    @action async resetFilters(): Promise<void> {
        this.isBusy = true;
        this.query = "";
        await this.performSearch();
        this.isBusy = false;
    }
}

export type DocumentUploadStoreErrors = {
    file?: string[];
    title?: string[];
};

export abstract class DocumentUploadStore {
    @observable isBusy: boolean = false;
    @observable errors: DocumentUploadStoreErrors = {};
    @IsNotEmpty({ message: "Название документа не может быть пустым." }) @observable title: string = "";
    @observable file?: File;

    abstract performUpload(): Promise<void>;

    @action async upload(): Promise<void> {
        const errors = await validate(this);
        if (errors.length !== 0 || !this.file) {
            this.errors = reduceValidationErrorsToErrors(errors);
            if (!this.file) this.errors = { file: ["Необходимо выбрать файл."], ...this.errors };
            return;
        }

        this.isBusy = true;
        await this.performUpload();
        this.errors = {};
        this.file = undefined;
        this.title = "";
        this.isBusy = false;
    }
}

export abstract class DocumentListStore<TDocumentDto> extends ReactTableStore<TDocumentDto> {
    abstract searchStore: DocumentSearchStore;
    abstract uploadStore: DocumentUploadStore;
}

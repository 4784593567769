import React from "react";
import { useRootStore } from "src/utilities";
import { supportedLocales } from "src/locales/locales";
import styles from "./LangChooser.module.css";
import { UserRouteNames } from "src/pages/user/UserRoutes";

export const LangChooser = () => {
    const store = useRootStore().rootStore;

    return (
        <select
            className={styles.langChooser}
            value={store.lang}
            onChange={(e) => {
                store.lang = e.target.value;
                window.localStorage.setItem("lang", e.target.value);
                if (store.routerStore.routerState.routeName === UserRouteNames.userApp) window.location.reload();
            }}
        >
            {supportedLocales.map((el, i) => (
                <option value={el} key={el + i}>
                    {el.toUpperCase()}
                </option>
            ))}
        </select>
    );
};

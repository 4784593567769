import { RemoteUiCrudEditorStore } from "src/stores/common/ContentEditorStore";
import {
    ListResult,
    RemoteUiDefinitionDto,
    Result,
    UPanelAccountDto,
    UPanelSubAccountCreateArgsDto,
    UPanelSubAccountDto,
} from "src/api";
import { action, observable } from "mobx";
import { RootStore } from "src/stores/RootStore";
import { AccountingRouteNames } from "src/pages/accounting/AccountingRoutes";

type AccountingSubAccountStoreArgs = {
    accountId: string;
    oid: string;
};

export class AccountingSubAccountStore extends RemoteUiCrudEditorStore<
    UPanelSubAccountDto,
    UPanelSubAccountCreateArgsDto,
    AccountingSubAccountStoreArgs
> {
    @observable
    account?: UPanelAccountDto;
    @observable
    balance: number = 0;

    constructor(root: RootStore) {
        super(root, AccountingRouteNames.subAccountList, AccountingRouteNames.subAccountList);
    }

    @action
    protected async onLoaded(): Promise<void> {
        const account = await this.root.userRpc.accountingAccount.getById(this.args.oid, this.args.accountId);
        this.account = account.value;
        if (this.selectedItemId === this.dummySelectedItemId) return;
        const balance = await this.root.userRpc.accountingSubAccount.getBalance(this.args.oid, this.selectedItemId);
        this.balance = balance.value;
    }

    protected getAll(): Promise<ListResult<UPanelSubAccountDto>> {
        return this.root.userRpc.accountingSubAccount.getAll(this.args.oid, this.args.accountId, this.skip, this.take);
    }

    protected async getById(id: string): Promise<UPanelSubAccountDto | undefined> {
        const response = await this.root.userRpc.accountingSubAccount.getById(this.args.oid, id);
        return response.success ? response.value : undefined;
    }

    protected createNew(args: UPanelSubAccountCreateArgsDto): Promise<Result> {
        throw new Error("Account creations are not supported.");
    }

    protected update(): Promise<Result> {
        throw new Error("Account updates are not supported.");
    }

    protected deleteById(id: string): Promise<Result> {
        throw new Error("Account removal is not supported.");
    }

    protected async getRemoteUi(): Promise<RemoteUiDefinitionDto<UPanelSubAccountCreateArgsDto>> {
        return await this.root.userRpc.accountingSubAccount.getRemoteUi(this.args.oid);
    }
}

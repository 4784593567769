import { RootStore } from "src/stores/RootStore";
import { RemoteUiCrudEditorStore } from "src/stores/common/ContentEditorStore";
import {
    ListResult,
    RemoteUiDefinitionDto,
    Result,
    UPanelApiConnectionCreateArgsDto,
    UPanelApiConnectionDto,
} from "src/api";
import { AdminRouteNames } from "src/pages/admin/AdminRoutes";

type AdminUPanelApiConnectionStoreArgs = {
    oid: string;
    pid: string;
};

export class AdminUPanelApiConnectionStore extends RemoteUiCrudEditorStore<
    UPanelApiConnectionDto,
    UPanelApiConnectionCreateArgsDto,
    AdminUPanelApiConnectionStoreArgs
> {
    constructor(root: RootStore) {
        super(root, AdminRouteNames.uPanelApiConnectionList, AdminRouteNames.uPanelApiConnectionAdd);
    }

    protected getAll(): Promise<ListResult<UPanelApiConnectionDto>> {
        return this.root.userRpc
            .createAdminInstanceRpc(this.args)
            .adminUPanelApiConnection.getAll(this.skip, this.take);
    }

    protected getById(id: string): Promise<UPanelApiConnectionDto> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminUPanelApiConnection.getById(id);
    }

    protected createNew(args: UPanelApiConnectionCreateArgsDto): Promise<Result> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminUPanelApiConnection.create(args);
    }

    protected update(args: UPanelApiConnectionCreateArgsDto): Promise<Result> {
        return this.root.userRpc
            .createAdminInstanceRpc(this.args)
            .adminUPanelApiConnection.update(this.selectedItemId, args);
    }

    protected deleteById(id: string): Promise<Result> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminUPanelApiConnection.delete(id);
    }

    protected getRemoteUi(): Promise<RemoteUiDefinitionDto<UPanelApiConnectionCreateArgsDto>> {
        return this.root.userRpc.createAdminInstanceRpc(this.args).adminUPanelApiConnection.getRemoteUi();
    }
}

import { SupportStore } from "src/components/SupportView/SupportStore";
import { ListResult, MessageBalloonDto } from "src/api";
import { action } from "mobx";
import { RootStore } from "src/stores/RootStore";

type AdminOrganizationSupportStoreArgs = {
    oid: string;
    pid: string;
    ooid: string;
};

export class AdminOrganizationSupportStore extends SupportStore<AdminOrganizationSupportStoreArgs> {
    constructor(private readonly root: RootStore) {
        super();
    }

    @action async loadMessages(): Promise<ListResult<MessageBalloonDto>> {
        return await this.root.userRpc
            .createAdminInstanceRpc(this.args)
            .adminOrganizationMessage.getAll(this.args.ooid, this.skip, this.take, "");
    }

    @action async sendMessage(): Promise<void> {
        await this.root.userRpc
            .createAdminInstanceRpc(this.args)
            .adminOrganizationMessage.send(this.args.ooid, this.message);
    }
}

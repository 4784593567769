import { RootStore } from "src/stores/RootStore";
import { RemoteUiCrudEditorStore } from "src/stores/common/ContentEditorStore";
import {
    UPanelAccountCreateArgsDto,
    UPanelAccountDto,
    UPanelEconomicEntityDto,
    ListResult,
    RemoteUiDefinitionDto,
    Result,
} from "src/api";
import { ProjectRouteNames } from "src/pages/project/ProjectRoutes";
import { action, observable } from "mobx";

type ProjectAccountStoreArgs = {
    economicEntityId: string;
    oid: string;
    pid: string;
};

export class ProjectAccountStore extends RemoteUiCrudEditorStore<
    UPanelAccountDto,
    UPanelAccountCreateArgsDto,
    ProjectAccountStoreArgs
> {
    @observable
    economicEntity?: UPanelEconomicEntityDto;

    constructor(root: RootStore) {
        super(root, ProjectRouteNames.accountList, ProjectRouteNames.accountAdd);
    }

    @action
    protected async onLoaded(): Promise<void> {
        const economicEntity = await this.root.userRpc.projectEconomicEntity.getById(
            this.args.oid,
            this.args.pid,
            this.args.economicEntityId
        );
        this.economicEntity = economicEntity.value;
    }

    protected createNew(args: UPanelAccountCreateArgsDto): Promise<Result> {
        return this.root.userRpc.projectAccount.create(this.args.oid, this.args.pid, this.args.economicEntityId, args);
    }

    protected getAll(): Promise<ListResult<UPanelAccountDto>> {
        return this.root.userRpc.projectAccount.getAll(
            this.args.oid,
            this.args.pid,
            this.args.economicEntityId,
            this.skip,
            this.take
        );
    }

    protected async getById(id: string): Promise<UPanelAccountDto | undefined> {
        const response = await this.root.userRpc.projectAccount.getById(this.args.oid, this.args.pid, id);
        return response.success ? response.value : undefined;
    }

    protected update(): Promise<Result> {
        throw new Error("Account updates are not supported.");
    }

    protected deleteById(id: string): Promise<Result> {
        throw new Error("Account removal is not supported.");
    }

    protected async getRemoteUi(): Promise<RemoteUiDefinitionDto<UPanelAccountCreateArgsDto>> {
        return await this.root.userRpc.projectAccount.getRemoteUi(this.args.oid, this.args.pid);
    }
}

import ru from "./ru";
import en from "./en";

type RuLocaleKeys = keyof typeof ru;
export type LocaleKeys = RuLocaleKeys;
export type LocaleDefinition = {
    [key in keyof typeof ru]: string;
};

export const supportedLocales = ["ru", "en"];

export function getLocaleMessages(locale: string): Record<string, string> | Record<string, []> {
    if (locale == "en") return en;
    return ru;
}

import { RootStore } from "src/stores/RootStore";
import { ListResult, MessageBalloonDto } from "src/api";
import { SupportStore } from "src/components/SupportView/SupportStore";

type OrganizationSupportRouteArgs = {
    oid: string;
};

export class OrganizationSupportStore extends SupportStore<OrganizationSupportRouteArgs> {
    constructor(private readonly root: RootStore) {
        super();
    }

    async loadMessages(): Promise<ListResult<MessageBalloonDto>> {
        return await this.root.userRpc.organizationMessage.getAll(this.args.oid, this.skip, this.take, "");
    }

    async sendMessage(): Promise<void> {
        await this.root.userRpc.organizationMessage.send(this.args.oid, this.message);
    }
}

import { CrudEditorStore } from "src/stores/common/ContentEditorStore";
import {
    ListResult,
    Result,
    UPanelEconomicEntityContractDto,
    UPanelEconomicEntityDto,
    UPanelEconomicEntityServiceDto,
    UPanelEconomicEntityServiceProvisionCreateArgsDto,
    UPanelEconomicEntityServiceProvisionDto,
} from "src/api";
import { RootStore } from "src/stores/RootStore";
import { action, computed, observable } from "mobx";
import { AutoCompleteBoxNotEmpty } from "src/utilities";
import { ConfigurableAutoCompleteStore } from "src/components/AutoCompleteSelect/AutoCompleteSelectStore";
import { AccountingRouteNames } from "src/pages/accounting/AccountingRoutes";

type AccountingServiceProvisionStoreArgs = {
    oid: string;
};

export class AccountingServiceProvisionStore extends CrudEditorStore<
    UPanelEconomicEntityServiceProvisionDto,
    UPanelEconomicEntityServiceProvisionCreateArgsDto,
    {},
    AccountingServiceProvisionStoreArgs
> {
    @AutoCompleteBoxNotEmpty({ message: "error__isNotEmpty__services" })
    @observable
    contracts: ConfigurableAutoCompleteStore<UPanelEconomicEntityContractDto>;
    @AutoCompleteBoxNotEmpty({ message: "error__isNotEmpty__services" })
    @observable
    services: ConfigurableAutoCompleteStore<UPanelEconomicEntityServiceDto>;
    @observable
    name = "";

    @observable
    economicEntity?: UPanelEconomicEntityDto;

    constructor(root: RootStore) {
        super(root, AccountingRouteNames.serviceProvisionList, AccountingRouteNames.serviceProvisionList);
        this.contracts = new ConfigurableAutoCompleteStore<UPanelEconomicEntityContractDto>({
            suggest: () => this.root.userRpc.accountingContract.getAll(this.args.oid, 0, 30),
            onSelectionChange: async () => {
                this.services.value = undefined;
                await this.services.suggest("");
            },
        });
        this.services = new ConfigurableAutoCompleteStore<UPanelEconomicEntityServiceDto>({
            suggest: () =>
                this.root.userRpc.accountingService.getAllByContract(this.args.oid, this.contracts.value!.id, 0, 30),
        });
    }

    @computed
    get description() {
        return this.services.value?.name && this.contracts.value?.name
            ? `Разовое предоставление услуги '${this.services.value?.name}' по контракту '${this.contracts.value?.name}'`
            : "";
    }

    @action
    protected async onLoaded(): Promise<void> {
        const response = await this.root.userRpc.accountingEconomicEntity.getCurrent(this.args.oid);
        this.economicEntity = response.value;
    }

    protected getAll(): Promise<ListResult<UPanelEconomicEntityServiceProvisionDto>> {
        return this.root.userRpc.accountingServiceProvision.getAll(this.args.oid, this.skip, this.take);
    }

    protected async getById(id: string): Promise<UPanelEconomicEntityServiceProvisionDto | undefined> {
        const response = await this.root.userRpc.accountingServiceProvision.getById(this.args.oid, id);
        return response.success ? response.value : undefined;
    }

    protected createNew(args: UPanelEconomicEntityServiceProvisionCreateArgsDto): Promise<Result> {
        throw new Error("Method not implemented.");
    }

    protected update(args: UPanelEconomicEntityServiceProvisionCreateArgsDto): Promise<Result> {
        throw new Error("Method not implemented.");
    }

    protected deleteById(id: string): Promise<Result> {
        throw new Error("Method not implemented.");
    }

    protected async setFields(item: UPanelEconomicEntityServiceProvisionDto) {
        const contract = await this.root.userRpc.accountingContract.getById(this.args.oid, item.contractId);
        const service = await this.root.userRpc.accountingService.getById(this.args.oid, item.serviceId);
        this.name = item.name;
        this.contracts.value = contract.value;
        this.services.value = service.value;
    }

    protected getFields(): UPanelEconomicEntityServiceProvisionCreateArgsDto {
        return {
            contractId: this.contracts.value?.id!,
            serviceId: this.services.value?.id!,
            name: this.name || this.description,
        };
    }

    protected resetFields(): void {
        this.contracts.value = undefined;
        this.services.value = undefined;
        this.name = "";
    }
}

import { SupportStore } from "src/components/SupportView/SupportStore";
import { ListResult, MessageBalloonDto } from "src/api";
import { action } from "mobx";
import { RootStore } from "src/stores/RootStore";

type AdminUserSupportStoreArgs = {
    oid: string;
    pid: string;
    uid: number;
};

export class AdminUserSupportStore extends SupportStore<AdminUserSupportStoreArgs> {
    constructor(private readonly root: RootStore) {
        super();
    }

    @action async loadMessages(): Promise<ListResult<MessageBalloonDto>> {
        return await this.root.userRpc
            .createAdminInstanceRpc(this.args)
            .adminUserMessage.getAll(this.args.uid, this.skip, this.take, "");
    }

    @action async sendMessage(): Promise<void> {
        await this.root.userRpc.createAdminInstanceRpc(this.args).adminUserMessage.send(this.args.uid, this.message);
    }
}

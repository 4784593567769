import { CrudEditorStore } from "src/stores/common/ContentEditorStore";
import {
    ListResult,
    Result,
    UPanelAccountDto,
    UPanelEconomicEntityContractCreateArgsDto,
    UPanelEconomicEntityContractDto,
    UPanelEconomicEntityDto,
    UPanelSubAccountDto,
} from "src/api";
import { observable } from "mobx";
import { ConfigurableAutoCompleteStore } from "src/components/AutoCompleteSelect/AutoCompleteSelectStore";
import { RootStore } from "src/stores/RootStore";
import { AccountingRouteNames } from "src/pages/accounting/AccountingRoutes";

type AccountingContractStoreArgs = {
    oid: string;
};

export class AccountingContractStore extends CrudEditorStore<
    UPanelEconomicEntityContractDto,
    UPanelEconomicEntityContractCreateArgsDto,
    {},
    AccountingContractStoreArgs
> {
    @observable name: string = "";
    @observable issuerAccounts: ConfigurableAutoCompleteStore<UPanelAccountDto>;
    @observable issuerSubAccounts: ConfigurableAutoCompleteStore<UPanelSubAccountDto>;
    @observable partnerAccounts: ConfigurableAutoCompleteStore<UPanelAccountDto>;
    @observable partnerSubAccounts: ConfigurableAutoCompleteStore<UPanelSubAccountDto>;

    @observable issuer?: UPanelEconomicEntityDto;
    @observable partner?: UPanelEconomicEntityDto;

    constructor(root: RootStore) {
        super(root, AccountingRouteNames.contractList, AccountingRouteNames.contractList);
        this.issuerAccounts = new ConfigurableAutoCompleteStore<UPanelAccountDto>({});
        this.issuerSubAccounts = new ConfigurableAutoCompleteStore<UPanelSubAccountDto>({});
        this.partnerAccounts = new ConfigurableAutoCompleteStore<UPanelAccountDto>({});
        this.partnerSubAccounts = new ConfigurableAutoCompleteStore<UPanelSubAccountDto>({});
    }

    protected async onLoaded(): Promise<void> {
        const response = await this.root.userRpc.accountingEconomicEntity.getCurrent(this.args.oid);
        this.partner = response.value;
    }

    protected getAll(): Promise<ListResult<UPanelEconomicEntityContractDto>> {
        const rpc = this.root.userRpc.accountingContract;
        return rpc.getAll(this.args.oid, this.skip, this.take);
    }

    protected async getById(id: string): Promise<UPanelEconomicEntityContractDto | undefined> {
        const response = await this.root.userRpc.accountingContract.getById(this.args.oid, id);
        return response.success ? response.value : undefined;
    }

    protected createNew(args: UPanelEconomicEntityContractCreateArgsDto): Promise<Result> {
        throw new Error("Method not supported.");
    }

    protected deleteById(id: string): Promise<Result> {
        throw new Error("Method not supported.");
    }

    protected update(args: UPanelEconomicEntityContractCreateArgsDto): Promise<Result> {
        throw new Error("Method not supported.");
    }

    protected getFields(): UPanelEconomicEntityContractCreateArgsDto {
        return {
            name: this.name,
            partnerId: this.partner?.id!,
            partnerSubAccountId: this.partnerSubAccounts.value?.id!,
            issuerSubAccountId: this.issuerSubAccounts.value?.id!,
        };
    }

    protected resetFields(): void {
        this.name = "";
        this.issuerAccounts.value = undefined;
        this.issuerSubAccounts.value = undefined;
        this.partner = undefined;
        this.partnerAccounts.value = undefined;
        this.partnerSubAccounts.value = undefined;
    }

    protected async setFields(item: UPanelEconomicEntityContractDto): Promise<void> {
        const u = this.root.userRpc;
        const issuer = await u.accountingEconomicEntity.getById(this.args.oid, item.issuerId);
        const issuerSub = await u.accountingSubAccount.getById(this.args.oid, item.issuerSubAccountId);
        const issuerAccount = await u.accountingAccount.getById(this.args.oid, issuerSub.value.accountId);
        const partner = await u.accountingEconomicEntity.getById(this.args.oid, item.partnerId);
        const partnerSub = await u.accountingSubAccount.getById(this.args.oid, item.partnerSubAccountId);
        const partnerAccount = await u.accountingAccount.getById(this.args.oid, partnerSub.value.accountId);
        this.issuer = issuer.value;
        this.partner = partner.value;
        this.partnerAccounts.value = partnerAccount.value;
        this.partnerSubAccounts.value = partnerSub.value;
        this.issuerAccounts.value = issuerAccount.value;
        this.issuerSubAccounts.value = issuerSub.value;
        this.name = item.name;
    }
}

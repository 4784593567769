import { RootStore } from "src/stores/RootStore";
import { RemoteUiCrudEditorStore } from "src/stores/common/ContentEditorStore";
import {
    ListResult,
    RemoteUiDefinitionDto,
    Result,
    UPanelAccountDto,
    UPanelEconomicEntityDto,
    UPanelSubAccountCreateArgsDto,
    UPanelSubAccountDto,
} from "src/api";
import { ProjectRouteNames } from "src/pages/project/ProjectRoutes";
import { action, observable } from "mobx";

type ProjectSubAccountStoreArgs = {
    economicEntityId: string;
    accountId: string;
    oid: string;
    pid: string;
};

export class ProjectSubAccountStore extends RemoteUiCrudEditorStore<
    UPanelSubAccountDto,
    UPanelSubAccountCreateArgsDto,
    ProjectSubAccountStoreArgs
> {
    @observable
    account?: UPanelAccountDto;
    @observable
    balance = 0;
    @observable
    economicEntity?: UPanelEconomicEntityDto;

    constructor(root: RootStore) {
        super(root, ProjectRouteNames.subAccountList, ProjectRouteNames.subAccountAdd);
    }

    @action
    protected async onLoaded(): Promise<void> {
        const economicEntity = await this.root.userRpc.projectEconomicEntity.getById(
            this.args.oid,
            this.args.pid,
            this.args.economicEntityId
        );
        const account = await this.root.userRpc.projectAccount.getById(
            this.args.oid,
            this.args.pid,
            this.args.accountId
        );
        this.account = account.value;
        this.economicEntity = economicEntity.value;
        if (this.selectedItemId !== this.dummySelectedItemId) {
            const balance = await this.root.userRpc.projectSubAccount.getBalance(
                this.args.oid,
                this.args.pid,
                this.selectedItemId
            );
            this.balance = balance.value;
        }
    }

    protected createNew(args: UPanelSubAccountCreateArgsDto): Promise<Result> {
        return this.root.userRpc.projectSubAccount.create(this.args.oid, this.args.pid, this.args.accountId, args);
    }

    protected getAll(): Promise<ListResult<UPanelSubAccountDto>> {
        return this.root.userRpc.projectSubAccount.getAll(
            this.args.oid,
            this.args.pid,
            this.args.accountId,
            this.skip,
            this.take
        );
    }

    protected async getById(id: string): Promise<UPanelSubAccountDto | undefined> {
        const response = await this.root.userRpc.projectSubAccount.getById(this.args.oid, this.args.pid, id);
        return response.success ? response.value : undefined;
    }

    protected update(): Promise<Result> {
        throw new Error("Account updates are not supported.");
    }

    protected deleteById(id: string): Promise<Result> {
        throw new Error("Account removal is not supported.");
    }

    protected async getRemoteUi(): Promise<RemoteUiDefinitionDto<UPanelSubAccountCreateArgsDto>> {
        return await this.root.userRpc.projectSubAccount.getRemoteUi(this.args.oid, this.args.pid);
    }
}

import { RootStore } from "src/stores/RootStore";
import React, { ChangeEvent, FC, FormEvent, useContext, useEffect } from "react";
import { MobXProviderContext } from "mobx-react";
import { TransitionHook } from "mobx-state-router/dist/types/router-store";
import { UserProfileStore } from "src/stores/user/UserProfileStore";
import {
    registerDecorator,
    ValidationArguments,
    ValidationError,
    ValidationOptions,
    ValidatorConstraint,
    ValidatorConstraintInterface,
} from "@keroosha/class-validator";
import pageStyles from "src/styles/page.module.css";
import { AutoCompleteSelectStore } from "src/components/AutoCompleteSelect/AutoCompleteSelectStore";

export type TimerProps = {
    runImmediately?: boolean;
    interval: number;
    onTick: () => any;
};

export class Timer extends React.Component<TimerProps> {
    id: any;
    render() {
        return null;
    }

    componentDidMount(): void {
        if (this.props.runImmediately) {
            this.props.onTick();
        }
        this.id = setInterval(() => {
            this.props.onTick();
        }, this.props.interval);
    }

    componentWillUnmount(): void {
        clearInterval(this.id);
    }
}

export type TransitionHookCallback<T> = (module: T) => Promise<unknown> | void;
export type TransitionHookWithCallback<T> = (callback?: TransitionHookCallback<T>) => TransitionHook;

export const declOfNum = (number: number, titles: string[]) => {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
};

export const toRussianDate = (raw?: string | null) => {
    if (!raw) return;
    return new Date(raw.split(".")[0]).toLocaleDateString("ru");
};

export const toRussianDateTime = (raw?: string | null) => {
    if (!raw) return;
    return new Date(raw.split(".")[0]).toLocaleString("ru");
};

export const mapChangeEventToValue = <T extends {}>(callback: (x: string) => void) => (
    x: ChangeEvent<HTMLInputElement>
) => callback(x.target.value);

export const preventDefaultEvent = <T extends {}>(callback: (x: FormEvent<HTMLFormElement>) => void) => (
    x: FormEvent<HTMLFormElement>
) => {
    x.preventDefault();
    callback(x);
};

export const useRootStore = () => useContext(MobXProviderContext) as { rootStore: RootStore };

export const useEnsureStoreLoaded = (x: { loading: boolean; initialized: boolean; load: () => void }) =>
    useEffect(() => {
        if (!x.loading && !x.initialized) x.load();
    }, [x]);

// Transition hooks

export const redirectIfProfileNotFilled: TransitionHookCallback<[UserProfileStore, unknown]> = async ([user]) => {
    if (!user.profile) await user.loadProfile();
};

export const reduceValidationErrorsToErrors = <T extends {}>(errors: ValidationError[]) =>
    errors.reduce((acc, x) => ({ ...acc, [x.property]: Object.values(x.constraints ?? {}) }), {} as T) as T;

export const computeInputStyleByErrorArray = (x: unknown[]) =>
    x.length > 0 ? pageStyles.inputError : pageStyles.inputGrey;

export function valueOrNull<T>(value: T | undefined): T | null {
    if (value === undefined) return null;
    return value;
}

export const toDTL = (millis: number) => new Date(millis).toISOString().slice(0, -1);

export function addEvent(eventName: string, callback: (e: any) => void) {
    const element = document as any;
    if (element.addEventListener) {
        element.addEventListener(eventName, (e: any) => callback(e || window.event), false);
    } else if (element.attachEvent) {
        element.attachEvent("on" + eventName, (e: any) => callback(e || window.event));
    } else {
        element["on" + eventName] = (e: any) => callback(e || window.event);
    }
}

export async function convertFileToBytes(file: File) {
    const buffer = await file.arrayBuffer();
    const integers = new Uint8Array(buffer);
    return Array.from(integers);
}

@ValidatorConstraint({ name: "Inn" })
export class InnValueConstraint implements ValidatorConstraintInterface {
    defaultMessage(args?: ValidationArguments): string {
        const error: LocalValidationError = {};
        validateInn(args?.value, error);
        return error.message ?? "";
    }

    validate(value: any, args?: ValidationArguments) {
        return validateInn(value);
    }
}

@ValidatorConstraint({ name: "Ogrn" })
export class OgrnValueConstraint implements ValidatorConstraintInterface {
    defaultMessage(args?: ValidationArguments): string {
        const error: LocalValidationError = {};
        validateOgrn(args?.value, error);
        return error.message ?? "";
    }

    validate(value: any, args?: ValidationArguments) {
        return validateOgrn(value);
    }
}

@ValidatorConstraint({ name: "Kpp" })
export class KppValueConstraint implements ValidatorConstraintInterface {
    defaultMessage(args?: ValidationArguments): string {
        const error: LocalValidationError = {};
        validateKpp(args?.value, error);
        return error.message ?? "";
    }

    validate(value: any, args?: ValidationArguments) {
        return validateKpp(value);
    }
}

export const CustomConstraint = (
    validator: ValidatorConstraintInterface | Function,
    validationOptions?: ValidationOptions
) => (object: any, propertyName: string) =>
    registerDecorator({
        target: object.constructor,
        propertyName: propertyName,
        options: validationOptions,
        constraints: [],
        validator: validator,
    });

type LocalValidationError = {
    code?: number;
    message?: string;
};

function validateInn(inn: string, error: LocalValidationError = {}): boolean {
    let result = false;
    if (!inn.length) {
        error.code = 1;
        error.message = "Укажите ИНН.";
    } else if (/[^0-9]/.test(inn)) {
        error.code = 2;
        error.message = "ИНН должен состоять только из цифр.";
    } else if ([10, 12].indexOf(inn.length) === -1) {
        error.code = 3;
        error.message = "ИНН должен состоять из 10 или 12 цифр.";
    } else {
        const checkDigit = function (inn: string, coefficients: any): number {
            let n = 0;
            for (let i in coefficients) {
                n += coefficients[i] * inn[i];
            }
            return parseInt(String((n % 11) % 10));
        };
        switch (inn.length) {
            case 10:
                const n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
                if (n10 === parseInt(inn[9])) {
                    result = true;
                }
                break;
            case 12:
                const n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
                const n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
                if (n11 === parseInt(inn[10]) && n12 === parseInt(inn[11])) {
                    result = true;
                }
                break;
        }
        if (!result) {
            error.code = 4;
            error.message = "Неверное контрольное число.";
        }
    }
    return result;
}

function validateOgrn(ogrn: string, error: LocalValidationError = {}): boolean {
    let result = false;
    if (!ogrn.length) {
        error.code = 1;
        error.message = "Укажите ОГРН.";
    } else if (/[^0-9]/.test(ogrn)) {
        error.code = 2;
        error.message = "ОГРН должен состоять только из цифр.";
    } else if (ogrn.length !== 13) {
        error.code = 3;
        error.message = "ОГРН должен состоять из 13 цифр.";
    } else {
        const n13 = parseInt((parseInt(ogrn.slice(0, -1)) % 11).toString().slice(-1));
        if (n13 === parseInt(ogrn[12])) {
            result = true;
        } else {
            error.code = 4;
            error.message = "Неверное контрольное число.";
        }
    }
    return result;
}

function validateKpp(kpp: string, error: LocalValidationError = {}): boolean {
    let result = false;
    if (!kpp.length) {
        error.code = 1;
        error.message = "Укажите КПП.";
    } else if (kpp.length !== 9) {
        error.code = 2;
        error.message = "КПП должен состоять из 9 знаков (цифр, букв A-Z).";
    } else if (!/^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(kpp)) {
        error.code = 3;
        error.message = "Некорректный КПП.";
    } else {
        result = true;
    }
    return result;
}

export function AutoCompleteBoxNotEmpty(validationOptions?: ValidationOptions) {
    return function (object: Object, propertyName: string) {
        registerDecorator({
            name: "autoCompleteBoxNotEmpty",
            target: object.constructor,
            propertyName: propertyName,
            constraints: [],
            options: validationOptions,
            validator: {
                validate(value: any, args: ValidationArguments) {
                    const store = value as AutoCompleteSelectStore<any>;
                    return store !== undefined && store.value !== undefined;
                },
            },
        });
    };
}

export function trimEnd(value: string, length: number): string {
    return value.length > length ? value.substring(0, length - 3) + "..." : value.substring(0, length);
}

import { lazy } from "react";

export const OrganizationPage = lazy(() => import("src/pages/org/Organization/OrganizationPage"));
export const OrganizationDocumentListPage = lazy(
    () => import("src/pages/org/OrganizationDocumentList/OrganizationDocumentListPage")
);
export const OrganizationSupportPage = lazy(() => import("src/pages/org/OrganizationSupport/OrganizationSupportPage"));
export const OrganizationAppPage = lazy(() => import("src/pages/org/OrganizationApp/OrganizationAppPage"));
export const OrganizationProjectListPage = lazy(
    () => import("src/pages/org/OrganizationProject/OrganizationProjectListPage")
);
export const OrganizationProjectAddPage = lazy(
    () => import("src/pages/org/OrganizationProject/OrganizationProjectAddPage")
);
export const OrganizationProjectEditPage = lazy(
    () => import("src/pages/org/OrganizationProject/OrganizationProjectEditPage")
);
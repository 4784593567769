import { RootStore } from "src/stores/RootStore";
import { CrudEditorStore } from "src/stores/common/ContentEditorStore";
import { ListResult, Result, UPanelAccountDto, UPanelInternalTransactionEntryDto, UPanelSubAccountDto } from "src/api";
import { action, observable } from "mobx";
import { TransactionTypeSelectStore } from "src/stores/project/ProjectTransactionStore";
import { AccountingRouteNames } from "src/pages/accounting/AccountingRoutes";

type InternalTransactionCreateArgsDto = {};

type AccountingTransactionStoreArgs = {
    accountId: string;
    subAccountId: string;
    oid: string;
};

export class AccountingTransactionStore extends CrudEditorStore<
    UPanelInternalTransactionEntryDto,
    InternalTransactionCreateArgsDto,
    {},
    AccountingTransactionStoreArgs
> {
    @observable
    account?: UPanelAccountDto;
    @observable
    subAccount?: UPanelSubAccountDto;
    @observable
    item?: UPanelInternalTransactionEntryDto;
    @observable
    transactionTypes: TransactionTypeSelectStore = new TransactionTypeSelectStore(() => this.refresh());

    constructor(root: RootStore) {
        super(root, AccountingRouteNames.transactionList, AccountingRouteNames.transactionList);
    }

    @action
    protected async onLoaded(): Promise<void> {
        const acc = await this.root.userRpc.accountingAccount.getById(this.args.oid, this.args.accountId);
        const sub = await this.root.userRpc.accountingSubAccount.getById(this.args.oid, this.args.subAccountId);
        this.subAccount = sub.value;
        this.account = acc.value;
    }

    protected getAll(): Promise<ListResult<UPanelInternalTransactionEntryDto>> {
        return this.root.userRpc.accountingInternalTransactionEntry.getAll(
            this.args.oid,
            this.args.subAccountId,
            this.transactionTypes.value?.id || null,
            this.skip,
            this.take
        );
    }

    protected async getById(id: string): Promise<UPanelInternalTransactionEntryDto | undefined> {
        const response = await this.root.userRpc.accountingInternalTransactionEntry.getById(this.args.oid, id);
        return response.success ? response.value : undefined;
    }

    protected update(args: InternalTransactionCreateArgsDto): Promise<Result> {
        throw new Error("Method not implemented.");
    }

    protected createNew(args: InternalTransactionCreateArgsDto): Promise<Result> {
        throw new Error("Method not implemented.");
    }

    protected deleteById(id: string): Promise<Result> {
        throw new Error("Method not implemented.");
    }

    protected getFields(): InternalTransactionCreateArgsDto {
        return {};
    }

    protected resetFields(): void {
        this.item = undefined;
    }

    protected setFields(item: UPanelInternalTransactionEntryDto): void {
        this.item = item;
    }
}

import { lazy } from "react";

export const AccountingAccountListPage = lazy(
    () => import("src/pages/accounting/AccountingAccount/AccountingAccountListPage")
);
export const AccountingAccountEditPage = lazy(
    () => import("src/pages/accounting/AccountingAccount/AccountingAccountEditPage")
);
export const AccountingSubAccountListPage = lazy(
    () => import("src/pages/accounting/AccountingSubAccount/AccountingSubAccountListPage")
);
export const AccountingSubAccountEditPage = lazy(
    () => import("src/pages/accounting/AccountingSubAccount/AccountingSubAccountEditPage")
);
export const AccountingTransactionListPage = lazy(
    () => import("src/pages/accounting/AccountingTransaction/AccountingTransactionListPage")
);
export const AccountingTransactionEditPage = lazy(
    () => import("src/pages/accounting/AccountingTransaction/AccountingTransactionEditPage")
);
export const AccountingServiceProvisionListPage = lazy(
    () => import("src/pages/accounting/AccountingServiceProvision/AccountingServiceProvisionList")
);
export const AccountingServiceListPage = lazy(
    () => import("src/pages/accounting/AccountingService/AccountingServiceListPage")
);
export const AccountingServiceEditPage = lazy(
    () => import("src/pages/accounting/AccountingService/AccountingServiceEditPage")
);
export const AccountingContractListPage = lazy(
    () => import("src/pages/accounting/AccountingContract/AccountingContractListPage")
);
export const AccountingContractEditPage = lazy(
    () => import("src/pages/accounting/AccountingContract/AccountingContractEditPage")
);

import { RootStore } from "src/stores/RootStore";
import { action } from "mobx";
import { OrgRouteNames } from "src/pages/org/OrganizationRoutes";
import { UserRouteNames } from "src/pages/user/UserRoutes";

export class OrganizationLoginStore {
    constructor(private readonly root: RootStore) {}

    @action async login(organizationId: string) {
        await this.root.routerStore.goTo(OrgRouteNames.organization, { "oid": organizationId });
    }

    @action async logOut() {
        await this.root.routerStore.goTo(UserRouteNames.organizations);
    }
}
